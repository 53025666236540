import React from 'react'
import { useParams } from 'react-router-dom';

export default function AviatorIframe() {
    const { Url } = useParams();
    // console.log("url-->", Url);

    const oldiframeUrl=`https://stream.1ex99.in/tv2?EventId=${Url}`;
    const iframeUrl=`https://tele.bazigarprediction.website/tv-cricket/tv.php?match_id=${Url}`;

  return (
    <>
    <div className="" style={{ height: "100vh" }}>
      <iframe
        height="100%"
        width="100%"
        src={oldiframeUrl}
        title=""></iframe>
    </div>
  </>
  )
}
