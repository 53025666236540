// matchNameAPI.js
import axios from "axios";

const baseURL = "https://battlemoney-auth-api.nakshtech.info/";

const matchNameAPI = axios.create({
  baseURL,
});

export const getMatchName = async (id, token) => {
  try {
    const response = await matchNameAPI.get(
      `GetAllCurrentMatches?eventTypeID=${id}&fromDate=" "&toDate=" "&searchByName=" "`,
      null,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    
    // console.log("matchDetails",response.data.data)

    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export default getMatchName;
