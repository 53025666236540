import React, { useEffect, useState } from "react";
import { MdScreenshotMonitor } from "react-icons/md";
import { MdChecklist } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchDashbetHistory } from "../../Redux/Slices/dashbetHistorySlice";

const LiveTvSection = () => {
  const { id } = useParams();

  const token = sessionStorage.getItem("token");
  const userId = sessionStorage.getItem("userData");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dashbetHistory, isLoading, error } = useSelector(
    (state) => state.dashbetHistory
  );
  const [tableData, setTableData] = useState([]);
  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [active, setActive] = useState(true);
  // useEffect(() => {
  //   const fetchDashData = async () => {
  //     try {
  //       const response = fetchDashbetHistory({ userId, token });
  //       console.log("DashBetHistory", response);
  //       setTableData(response);
  //     } catch (error) {
  //       console.error("Error fetching DAshbet history:", error);
  //     }
  //   };

  //   fetchDashData();
  // }, [dispatch, userId, token]);

  // useEffect(() => {
  //   dispatch(
  //     fetchDashbetHistory({
  //       data: {
  //         uid: userId,
  //         fromDate: "",
  //         toDate: "",
  //       },
  //       token,
  //     })
  //   );
  // }, [dispatch]);
  return (
    <div className="px-1 d-lg-block d-none">
      <div className="TV-outer-div px-3 py-2">
        <div className={active ? "live-tv-card" : "live-tv-card-active"}>
          <div className="d-flex justify-content-between tv-card pb-0 mb-0 p-1">
            <div className="d-flex mt-1 ms-1 ">
              <MdScreenshotMonitor className="tv-icon" />
              <p className="ms-2 mb-2">LIVE TV</p>
            </div>

            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="flexSwitchCheckDefault"
                value={active}
                onChange={() => setActive(!active)}
              />
            </div>
          </div>
          {active && (
            <iframe
              allowFullScreen={true}
              style={{
                width: "100%",
                height: "210px",
                border: "none",
                opacity: "1",
                visibility: "visible",
                color: "white ",
              }}
              id="frame_video_2"
              // src={`https://stream.1ex99.in/tv2?EventId=${id}`}
              src={`https://betexch.online/AViframe/${id}`}
            ></iframe>
          )}
        </div>
        <div className="matched-bets mt-3">
          <div
            className="d-flex justify-content-between tv-card mb-0 pb-0 p-1"
            style={{ overflow: "scroll" }}
          >
            {/* <div className="d-flex mt-1 ms-1 mb-0 "> */}
            <div className="" style={{ height: "200px" }}>
              <div className="mybet_table">
                <div className="table-responsive">
                  <table className="tablee">
                    <thead>
                      <tr className="text-white">
                        <th className="text-white">Matched Bet</th>

                        <th className="text-white">Odds</th>
                        <th className="text-white">Stake</th>
                        <th className="text-white">Profit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dashbetHistory?.map((item) => {
                        // console.log("item", item.team.length);
                        return item.back > 0 ? (
                          <tr
                            key={item.id}
                            style={{ backgroundColor: "#0b9cf1" }}
                          >
                            <td className="td_bet_items">{item.team}</td>
                            <td>
                              {item.lay > 0 ? (
                                <div className="">{item.lay}</div>
                              ) : (
                                <div className="">{item.back}</div>
                              )}
                            </td>
                            <td>{item.stake}</td>
                            <td>{item.odds || 0}</td>
                          </tr>
                        ) : (
                          <tr
                            key={item.id}
                            style={{ backgroundColor: "#f37a13" }}
                          >
                            <td className="td_bet_items">{item.team}</td>

                            <td>
                              {item.lay > 0 ? (
                                <div className="">{item.lay}</div>
                              ) : (
                                <div className="">{item.back}</div>
                              )}
                            </td>
                            <td>{item.stake}</td>
                            <td>{item.odds || 0}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="bet-matches ms-2 p-2">AUSTRALIA VS NEWZEELAND</div>
          <div className="no-bets ms-1 p-2">NO BETS</div> */}
        </div>
      </div>
    </div>
  );
};

export default LiveTvSection;
