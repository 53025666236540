import React, { useEffect, useState } from "react";
import { fetchUserdepositHistoryData } from "../../Redux/Slices/depositHistorySlice";
import Table_Buttons from "../Table_Buttons/Table_Button";
import Table from "../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function DepositHistory() {
  const dispatch = useDispatch();
  const { depositHistory, isLoading, error } = useSelector(
    (state) => state.depositHistory
  );
  const invalidToken = useSelector(state => state.depositHistory.invalidToken);
  const navigate = useNavigate();

  // useEffect(() => {
  //   if (invalidToken) {
  //     sessionStorage.removeItem("userData");
  //     sessionStorage.removeItem("token");
  //     navigate ('/');
  //   }
  // }, [invalidToken]);

  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);

  const token = sessionStorage.getItem("token");
  // console.log("storedJwtToken ",token)
  const userId = sessionStorage.getItem("userData");
  // console.log("stored userdata ",userId);

  useEffect(() => {
    dispatch(fetchUserdepositHistoryData({ id: userId, token }));
  }, [dispatch, userId, token]);

  // useEffect(() => {
  //   if (depositHistory) {
  //     console.log(" depositHistory API:", depositHistory);
  //   }
  // }, [depositHistory]);

  useEffect(() => {
    if (!isLoading && depositHistory.length > 0) {
      // console.log(depositHistory.length)
      let arr = depositHistory.map((item, index) => ({
        sr: item.row,
        uid: item.user_id,
        Request_date: item.Request_date,
        Request_amount: item.USDValue,
        amountusd: item.payBNB,
        AGCAmount: item.RequestToken,
        admincharge: item.PaidTokentxnid,
        txn: (
          <a
            className="link-light"
            href={`https://bscscan.com/tx/${item.PayBNBtxn}`}
            target="_blank"
            rel="noreferrer">
            View Txn
          </a>
        ),
        type: item.type,
      }));

      // console.log("arr",arr)
      setreferralApi(arr);
    }
    if (error) {
      console.log("depositHistory call is having error", error);
    }
  }, [isLoading, depositHistory]);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);
  var [matching_income, set_matching_income] = useState({
    cols: [
      { Header: "Sr.No", accessor: "sr" },
      { Header: "User ID", accessor: "uid" },
      { Header: "USDT Value", accessor: "Request_amount" },
      { Header: "Type", accessor: "type" },
      { Header: "	Pay USDT", accessor: "amountusd" },
      { Header: "Pay USDT TXN", accessor: "txn" },
      { Header: "Request Token", accessor: "AGCAmount" },
      { Header: "Date & Time", accessor: "Request_date" },
    ],
  });

  return (
    <div className="col-lg-10 com-h">
      <div className="row">
        <div className="col-12 p-3">
          <div className="bet-h ">
            <h4 className="p-2 ms-2">Deposit History</h4>

            <Table data={[...currentPost]} columns={matching_income.cols} />
            <Table_Buttons
              setcurrentPage={setcurrentPage}
              currentPage={currentPage}
              totalData={referralApi.length}
              listPerpage={listPerpage}
              handleRowsPerPageChange={setlistPerpage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
