import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import "./TeenPatti.css";
import a from "../../Media/10SS.png";
import b from "../../Media/3SS.png";
import c from "../../Media/2HH.png";
import d from "../../Media/AHH.png";
import e from "../../Media/KHH.png";
import { MdCancel } from "react-icons/md";
import { FaTrophy } from "react-icons/fa";
import { io } from "socket.io-client";
import { fetchuserBalance } from "../../Redux/Slices/userBalanceSlice";
import { toast } from "react-toastify";
import { casinoBet } from "../../Redux/Slices/casinobetSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Modal from "./Modal";
import Table from "./Table";
import { fetchCasinobetHistory } from "../../Redux/Slices/casinobetHistory";
import { rulesData } from "../../Redux/Slices/rulesSlice";
import Rules_Modal from "../Rules_Modal";
import { API } from "../../API/API";

const TeenPatti = () => {
  // console.log("games", games);

  const { id } = useParams();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const [showresultModal, setShowresultModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [betDetails, setbetDetails] = useState({});
  const { casinobetHistory, loading } = useSelector(
    (state) => state.casinobetHistory
  );

  const [rulesModal, setRulesModal] = useState(false);
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);

  const CasinoGameResult_API = async (mid) => {
    
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      // console.log("CasinoGameResult 32:", response.data.data[0]);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"></div>
        <div className="teen-results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound} </div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div ">
                <div className="text-center">Player A</div>
                <div className="d-flex">
                  {casinoGameResult?.slice(0,3).map((item, index) => (
                    <div className="gamecard-div mx-1" key={index}>
                      <img
                        src={item.image}
                        className="img-fluid"
                        alt={`Player A Card ${index}`}
                      />
                    </div>
                  ))}

                  {/* <div className="gamecard-div mx-1">
                    <img src={a} className="img-fluid" alt="a" />
                  </div>
                  <div className="gamecard-div mx-1">
                    <img src={b} className="img-fluid" alt="a" />
                  </div> */}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div  ">
                <div className="d-flex justify-content-between">
                  <div className="text-center">Player B</div>
                  <FaTrophy className="trophy-icon" />
                </div>

                <div className="d-flex">
                {casinoGameResult?.slice(3,6).map((item, index) => (
                    <div className="gamecard-div mx-1" key={index}>
                      <img
                        src={item.image}
                        className="img-fluid"
                        alt={`Player A Card ${index}`}
                      />
                    </div>
                  ))}
                  {/* <div className="gamecard-div mx-1">
                    <img src={a} className="img-fluid" alt="a" />
                  </div>
                  <div className="gamecard-div mx-1">
                    <img src={b} className="img-fluid" alt="a" />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const SOCKET_URL = "https://battlemoney-socket-api.nakshtech.info/";

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        console.log("32cardA", parsedData[0]);
        setResults(parsedData[0].resultUrlResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    dispatch(fetchCasinobetHistory({ uid, token,id }));
  }, [dispatch, uid,reqRes?.t1[0]?.mid]);



  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(fetchCasinobetHistory({ uid, token, id }));
      dispatch(fetchuserBalance({ id: uid, token }));
    }, 10000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [dispatch, uid, reqRes?.t1[0]?.mid]);
  useEffect(() => {
    dispatch(rulesData());
  }, []);

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {loading ? (
              <tr>
                <td colSpan="6">Loading ...</td>
              </tr>
            ) : casinobetHistory.length > 0 ? (
              casinobetHistory.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  return (
    <div className="col-lg-10 ">
      <div className="row home ">
        <div className="col-lg-8 p-1">
          <div className="teenpatti-game-div pt-2 p-2">
            <div className="teenpatti-game-h d-flex justify-content-between  ">
              <div className=" px-2">
                <p className=" mt-1 teenpatti-h-p">{name}</p>
              </div>
              <div>
                <p className="mt-1 px-2 teenpatti-h-p">
                  Round ID: {reqRes?.t1[0]?.mid || 0.0} | Left Time:{" "}
                  {reqRes?.t1[0]?.autotime || 0} |{" "}
                  <span onClick={() => setRulesModal(true)}>Rules</span>
                </p>
              </div>
            </div>
            <div className="card  teenpatti-game-card mt-1">
              <div className="row">
                <div className="col-10">
                  <div className="" style={{ height: "170%", width: "120%" }}>
                    <iframe
                      height="100%"
                      width="100%"
                      src={`https://battlemoney.live/iframe/${id}`}
                      title=""></iframe>
                  </div>
                </div>
                <div className="col-2 d-flex justify-content-end">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            {/* <-------------------------------Back-lay-table----------> */}
            <div className="row">
              <div className="col-lg-12">
                <div className="table-h-div row">
                  <div className="col-5 d-flex align-items-center ps-2"></div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2">
                    Back
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center ">
                    Lay
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6">
                <div className="table-h-div row">
                  <div className="col-5 d-flex align-items-center ps-2"></div>
                  <div className="col-3 d-flex align-items-center justify-content-center px-2">
                    Back
                  </div>
                  <div className="col-3 d-flex align-items-center justify-content-center ">
                    Lay
                  </div>
                </div>
              </div> */}
            </div>

            {reqRes?.t2?.map((req, index) => (
              <div className="row" key={index}>
                <div className="col-lg-12">
                  <Table
                    key={req.sid}
                    req={req}
                    setShowModal={setShowModal}
                    betDetails={betDetails}
                    setbetDetails={setbetDetails}
                    period={reqRes.t1[0].mid}
                    index={index}
                  />
                </div>
              </div>
            ))}

            {/* <<<<<<<<<<<<<<<<<<<<<<<-----------------------My-Bets-table-------------->>>>>>>>>> */}
            <div className="My-Bets-table">
              <div className="color-game-h mt-2">
                <div className="px-2 pt-1">
                  <p>Open Bets</p>
                </div>
              </div>
              {HistoryTable}

              {/* <div className="card-table-inner-row row"></div> */}
            </div>
            <div className="Results-table">
              <div className="color-game-h mt-2">
                <div className="px-2 pt-1">
                  <p>Last 10 Results</p>
                </div>
              </div>
              <div className="card rst-card py-1 d-flex">
                <div className="rst-row d-flex">
                  {results?.map((result, index) => {
                    const item = reqRes.t2.find(
                      (item) => item.sid === result?.result
                    );
                    return (
                      <div
                        onClick={() => {
                          setMidRound(result.mid);
                          CasinoGameResult_API(result.mid);
                          setShowresultModal(true);
                        }}
                        className="rst-btn ms-1"
                        key={index}>
                        {item && <>{item.nat.split(" ")[1]}</>}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <RightSidebar />

        {showModal && (
          <Modal
          id={id}
            name={name}
            betDetails={betDetails}
            setbetDetails={setbetDetails}
            setShowModal={setShowModal}
          />
        )}
        {showresultModal && <ResultsModal />}
        {rulesModal && <Rules_Modal closeFunction={setRulesModal} />}
      </div>
    </div>
  );
};

export default TeenPatti;
