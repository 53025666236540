import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import "./Andarbahar.css";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { fetchCasinobetHistory } from "../../Redux/Slices/casinobetHistory";
import c1 from "../../Media/1.jpg";
import c2 from "../../Media/2.jpg";
import c3 from "../../Media/3.jpg";
import c4 from "../../Media/4.jpg";
import c5 from "../../Media/5.jpg";
import c6 from "../../Media/6.jpg";
import c7 from "../../Media/7.jpg";
import c8 from "../../Media/8.jpg";
import c9 from "../../Media/9.jpg";
import c10 from "../../Media/10.jpg";
import c11 from "../../Media/11.jpg";
import c12 from "../../Media/12.jpg";
import c13 from "../../Media/13.jpg";
import Spade from "../../Media/spade-2.png";
import Heart from "../../Media/heart-2.png";
import Club from "../../Media/club-2.png";
import Diamond from "../../Media/diamond-2.png";
import { rulesData } from "../../Redux/Slices/rulesSlice";
import Rules_Modal from "../Rules_Modal";
import { API } from "../../API/API";
import { FaTrophy } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import a from "../../Media/10SS.png";
import b from "../../Media/3SS.png";
import Modal from "../TeenPatti/Modal";
import { fetchuserBalance } from "../../Redux/Slices/userBalanceSlice";

function Andarbahar() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const imageImports = [c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c12, c13];
  const cardImages = [Spade, Club, Heart, Diamond];
  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [showresultModal, setShowresultModal] = useState(false);
  const [showDataModal, setShowdataModal] = useState(false);
  const [rulesModal, setRulesModal] = useState(false);

  const { casinobetHistory, loading } = useSelector(
    (state) => state.casinobetHistory
  );

  const [betno, setBetNum] = useState(0);
  const [betDetails, setbetDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      // console.log("CasinoGameResult andarB:", response.data.data[0]);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"></div>
        <div className="teen-results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound}</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div ">
                <div className="text-center">Player A</div>
                <div className="d-flex">
                {casinoGameResult?.slice(0,5).map((item, index) => (
                    <div className="gamecard-div mx-1" key={index}>
                      <img
                        src={item.image}
                        className="img-fluid"
                        alt={`Player A Card ${index}`}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div  ">
                <div className="d-flex justify-content-between">
                  <div className="text-center">Player B</div>
                  <FaTrophy className="trophy-icon" />
                </div>

                <div className="d-flex">
                {casinoGameResult?.map((item, index) => (
                    <div className="gamecard-div mx-1" key={index}>
                      <img
                        src={item.image}
                        className="img-fluid"
                        alt={`Player A Card ${index}`}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const SOCKET_URL = "https://battlemoney-socket-api.nakshtech.info/";

  useEffect(() => {
    // console.log("Odd socket before");
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        // console.log("parsed", parsedData[0].reqResponse);
        // console.log("parsed_result", parsedData[0].resultUrlResponse);
        setResults(parsedData[0].resultUrlResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    dispatch(fetchCasinobetHistory({ uid, token,id }));
  }, [dispatch, uid,reqRes?.t1[0]?.mid]);


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(fetchCasinobetHistory({ uid, token, id }));
      dispatch(fetchuserBalance({ id: uid, token }));
    }, 10000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [dispatch, uid, reqRes?.t1[0]?.mid]);


  useEffect(() => {
    dispatch(rulesData());
  }, [dispatch]);

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {loading ? (
              <tr>
                <td colSpan="6">Loading ...</td>
              </tr>
            ) : casinobetHistory.length > 0 ? (
              casinobetHistory.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );


  const handleModal = (value,nat,no) => {
 
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period:0,
      nat: nat,
      number:no
    });
    setShowModal(true);
  };

  return (
    <div className="col-lg-10 home">
      <div className="row">
        <div className="col-lg-8 px-2 mt-1">
          <div className="hei">
            <div className="teenpatti-game-h d-flex justify-content-between  ">
              <div className=" px-2">
                <p className=" mt-1 ">{name}</p>
              </div>
              <div>
                <p className="mt-1 px-2">
                  Round ID: {reqRes?.t1[0]?.mid || 0.0} | Left Time:{" "}
                  {reqRes?.t1[0]?.autotime || 0} |{" "}
                  <span onClick={() => setRulesModal(true)}>Rules</span>
                </p>
              </div>
            </div>
            <div className="card  teenpatti-game-card p-1 mt-1">
              <div className="row">
                <div className="col-10 ">
                  <div className="" style={{ height: "170%", width: "120%" }}>
                    <iframe
                      height="100%"
                      width="100%"
                      src={`https://battlemoney.live/iframe/${id}`}
                      title=""></iframe>
                  </div>
                </div>
                <div className="col-2 d-flex justify-content-end">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>
            <div className="mains">
              <div className="row">
                <div className=" col-lg-6">
                  <div className=" bet-a">
                    <div className="a-title">
                      {" "}
                      {reqRes?.t2[0]?.nat[reqRes?.t2[0]?.nat.length - 1]}
                    </div>{" "}
                    {reqRes?.t2?.slice(0, 3)?.map((item, index) => (
                      <div
                        className={
                          item.nat === "1st Bet" || item.nat === "2nd Bet"
                            ? "first-bet"
                            : "sa"
                        }
                        key={index}>
                        {item.gstatus == 0 ? (
                          <>
                            <div className="overs">
                              <div>{item.nat}</div>
                              <div className="mt-1">
                                {" "}
                                <i className="fa fa-lock"></i>
                              </div>
                            </div>
                            <div className="book" style={{ color: "white" }}>
                              0
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className=""
                              onClick={() => {
                                // setShowModal(true);
                                // setBetNum(index + 1);
                                handleModal(item.b1,item.nat,index+1);

                              }}>
                              <div>{item.nat}</div>
                              <div className="mt-1">{item.b1}</div>
                            </div>
                            <div className="book" style={{ color: "white" }}>
                              0
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                    <div className="a-title">
                      {" "}
                      {reqRes?.t2[0]?.nat[reqRes?.t2[0]?.nat.length - 1]}
                    </div>
                  </div>{" "}
                </div>
                <div className="col-lg-6 ">
                  <div className="bet-a">
                    <div className="a-title">
                      {" "}
                      {reqRes?.t2[3]?.nat[reqRes?.t2[3]?.nat.length - 1]}
                    </div>{" "}
                    {reqRes?.t2?.slice(3, 6)?.map((item, index) => (
                      <div
                        className={
                          item.nat === "1st Bet" || item.nat === "2nd Bet"
                            ? "first-bet"
                            : "sa"
                        }
                        key={index}>
                        {item.gstatus == 0 ? (
                          <>
                            <div className="overs">
                              <div>{item.nat}</div>
                              <div className="mt-1">
                                {" "}
                                <i className="fa fa-lock"></i>
                              </div>
                            </div>
                            <div className="book" style={{ color: "white" }}>
                              0
                            </div>
                          </>
                        ) : (
                          <>
                            <div
                              className=""
                              onClick={() => {
                                // setShowModal(true);
                                // setBetNum(index + 1);
                                handleModal(item.b1,item.nat,index+4);

                              }}>
                              <div>{item.nat}</div>
                              <div className="mt-1">{item.b1}</div>
                            </div>
                            <div className="book" style={{ color: "white" }}>
                              0
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                    <div className="a-title">
                      {" "}
                      {reqRes?.t2[3]?.nat[reqRes?.t2[3]?.nat.length - 1]}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row row5 mt-2">
              <div className="col-lg-6">
                <div className="ab-2-box">
                  <div className="row row5">
                    {reqRes?.t2?.slice(23, 25)?.map((item, index) => (
                      <div className="col-6 text-center" key={index}>
                        <div className="bltitle text-center">
                          <b data-v-25d02ca0=""> {item.nat.split(" ")[1]}</b>
                        </div>{" "}
                        <center>
                          {item.gstatus == 0 ? (
                            <div className="back mt-1 blbox suspended overs">
                              <span className="odd tex-center">
                                {" "}
                                <i className="fa fa-lock"></i>
                              </span>
                            </div>
                          ) : (
                            <div
                              className="back mt-1 blbox suspended"
                              onClick={() => {
                                // setShowModal(true);
                                // setBetNum(index + 1);
                                handleModal(item.b1,item.nat,index+24);

                              }}>
                              <span className="odd tex-center">{item.b1}</span>
                            </div>
                          )}
                        </center>
                        <div className="mt-1">
                          <span style={{ color: "white" }}>0</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>{" "}
              <div className="col-lg-6">
                <div className="ab-2-box">
                  <div className="row row5">
                    {reqRes?.t2?.slice(19, 23)?.map((item, index) => (
                      <div className="col-3 text-center" key={index}>
                        <div className="bltitle">
                          <img
                            data-v-25d02ca0=""
                            src={cardImages[index]}
                            // src={Spade}
                            alt={item.nat}
                          />
                        </div>{" "}
                        <center>
                          <center>
                            {item.gstatus == 0 ? (
                              <div className="back mt-1 blbox suspended overs">
                                <span className="odd tex-center">
                                  {" "}
                                  <i className="fa fa-lock"></i>
                                </span>
                              </div>
                            ) : (
                              <div
                                className="back mt-1 blbox suspended"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(index + 1);
                                  handleModal(item.b1,item.nat,index+20);

                                }}>
                                <span className="odd tex-center">
                                  {item.b1}
                                </span>
                              </div>
                            )}
                          </center>
                        </center>
                        <div className="mt-1">
                          <span style={{ color: "white" }}>0</span>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="row row5 mt-2">
              <div className="col-12">
                <div className="ab-2-box">
                  <div className="row" />{" "}
                  <div className="row">
                    <div className="col-12 text-center">
                      {reqRes?.t2[6]?.b1}
                    </div>
                  </div>{" "}
                  <div className="row mt-1">
                    <div className="col-12 text-center">
                      {reqRes?.t2?.slice(6, 19)?.map((item, index) => (
                        <div className="m-r-5 card-image">
                          <div className="position-relative">
                            {item.gstatus == 0 && (
                              <div className="suspended__2">
                                <i className="fa fa-lock"></i>
                              </div>
                            )}
                            <img
                              data-v-25d02ca0=""
                              src={imageImports[index]}
                              alt={item.nat}
                              onClick={() => {
                                // setShowModal(true);
                                // setBetNum(index + 1);
                                handleModal(item.b1,item.nat,index+7);

                              }}
                            />
                          </div>{" "}
                          <div className="ubook text-center m-t-5">
                            <b style={{ color: "white" }}>0</b>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="My-Bets-table">
              <div className="color-game-h mt-2">
                <div className="px-2 pt-1">
                  <p>Open Bets</p>
                </div>
              </div>
              {HistoryTable}
            </div>
            <div className="Results-table">
              <div className="color-game-h mt-2">
                <div className="px-2 pt-1">
                  <p>Last 10 Results</p>
                </div>
              </div>
              <div className="card rst-card py-1 d-flex">
                <div className="rst-row d-flex">
                  {results?.map((result, index) => {
                    const item = reqRes.t2.find(
                      (item) => item.sid === result?.result
                    );

                    return (
                      <div
                        onClick={() => {
                          setMidRound(result.mid);
                          CasinoGameResult_API(result.mid);
                          setShowresultModal(true);
                        }}
                        className="rst-btn ms-1"
                        key={index}>
                        {item && (
                          <>
                            {item.nat[item.nat.length - 1] == "t" ? "B" : "A"}
                          </>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
        <RightSidebar />
        {showModal && (
          <Modal
            id={id}
            name={name}
            betDetails={betDetails}
            setbetDetails={setbetDetails}
            setShowModal={setShowModal}
          />
        )}
        {showresultModal && <ResultsModal />}
        {rulesModal && <Rules_Modal closeFunction={setRulesModal} />}
      </div>
    </div>
  );
}

export default Andarbahar;
