import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const API = axios.create({
  baseURL: "https://battlemoney-auth-api.nakshtech.info/",
});

export const fetchCasinobetHistory = createAsyncThunk(
  "user/fetchDashbetHistory",
  async ({ uid, token,id },{rejectWithValue}) => {
  // console.log("userId",uid,id)
    try {
      const response = await API.post(
        "/GetCasinoBetReport",
        {
          uid: uid,
          gameid: id,
          fdate: "",
          tdate: "",
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("bethistory", response );
      return response.data.data[0];
    } catch (error) {
      throw rejectWithValue(error.response.data);
    }
  }
);

const casinobetHistorySlice = createSlice({
  name: "casinobetHistory",
  initialState: {
    casinobetHistory: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCasinobetHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCasinobetHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.casinobetHistory = action.payload;
      })
      .addCase(fetchCasinobetHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action;
      });
  },
});

export default casinobetHistorySlice.reducer;
