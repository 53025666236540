import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useNavigate } from "react-router-dom";
import { fetchAccountStatement } from "../../Redux/Slices/accountStatementSlice";
import Table from "../Table/Table";
import Table_Buttons from "../Table_Buttons/Table_Button";

const AccountStatement = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { accountStatement, isLoading, error } = useSelector(
    (state) => state.accountStatement
  );

  const [referralApi, setreferralApi] = useState([]);
  const [currentPage, setcurrentPage] = useState(1);
  const [listPerpage, setlistPerpage] = useState(10);
  const [todate, settodate] = useState("");
  const [fromdate, setfromdate] = useState("");

  const userId = sessionStorage.getItem("userData");
  const token = sessionStorage.getItem("token");

  useEffect(() => {
    const data = {
      uid: userId,
      fromDate: fromdate,
      toDate: todate,
    };
    dispatch(fetchAccountStatement({ data, token }));
  }, [dispatch, token]);

  useEffect(() => {
    if (accountStatement) {
      console.log("Fetching account statement", accountStatement);
    }
  }, [accountStatement]);

  useEffect(() => {
    if (error) {
      console.log("AccountStatement call error:", error);
      if (
        error.response &&
        error.response.data.showableMessage ===
          "Please login to access this resource"
      ) {
        navigate("/");
      }
    }
  }, [error, navigate]);

  useEffect(() => {
    if (!isLoading && accountStatement.length > 0) {
      // console.log(depositHistory.length)
      let arr = accountStatement.map((item, index) => ({
        sr: index + 1,
        uid: item.user_id,
        fromId: item.fromId,
        fromName: item.fromName,
        fromType: item.fromType,
        receivedDate: item.receivedDate,
        amount: item.amount,
        deduct: item.deduct,
        balance: item.balance,
        remark: item.remark,
      }));

      // console.log("arr",arr)
      setreferralApi(arr);
    }
  }, [isLoading, accountStatement]);

  const indexOfLastPost = currentPage * listPerpage;
  const indexOfFirstPage = indexOfLastPost - listPerpage;
  const currentPost = referralApi.slice(indexOfFirstPage, indexOfLastPost);
  var [matching_income, set_matching_income] = useState({
    cols: [
      { Header: "Sr.No", accessor: "sr" },
      { Header: "From Id", accessor: "fromId" },
      { Header: "From Type", accessor: "fromType" },
      { Header: "From Name", accessor: "fromName" },
      { Header: "Date", accessor: "receivedDate" },
      { Header: "Credit", accessor: "amount" },
      { Header: "Debit", accessor: "deduct" },
      { Header: "Balance", accessor: "balance" },
      { Header: "Remark", accessor: "remark" },
    ],
  });

  const handleSearch = () => {
    const data = {
      uid: userId,
      fromDate: fromdate,
      toDate: todate,
    };
    dispatch(fetchAccountStatement({ data, token }));
  };

  return (
    <div className="col-lg-10 com-h">
      <div className="row">
        <div className="col-12 p-3">
          <div className="bet-h ">
            <h4 className="p-2 ms-2">Account Statement</h4>

            <div className="dates-div p-2 d-flex ">
              <div className="row">
                <div className="col-md-5 mt-1">
                  <div className="d-flex">
                    <div className="from d-flex align-items-center justify-content-center">
                      From:
                    </div>
                    <div className="">
                      <input
                        className="from-control"
                        type="date"
                        value={fromdate}
                        onChange={(e) => setfromdate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-5 mt-1">
                  <div className="d-flex ">
                    <div className="from d-flex align-items-center justify-content-center">
                      To:
                    </div>
                    <div className="">
                      <input
                        className="from-control"
                        type="date"
                        value={todate}
                        onChange={(e) => settodate(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-2 mt-1">
                  <button
                    className="btn btn-success "
                    onClick={() => handleSearch()}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      className="bi bi-search"
                      viewBox="0 0 16 16">
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                    </svg>{" "}
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="">
              <Table data={[...currentPost]} columns={matching_income.cols} />
              <Table_Buttons
                setcurrentPage={setcurrentPage}
                currentPage={currentPage}
                totalData={referralApi.length}
                listPerpage={listPerpage}
                handleRowsPerPageChange={setlistPerpage}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountStatement;
