import React, { memo, useEffect, useState } from "react";
import MatchOdds from "./MatchOdds";
import BookMaker from "./BookMaker";
import Fancy from "./Fancy";
import { io } from "socket.io-client";
import { useParams } from "react-router-dom";
import Completed from "./Completed";
import Session from "./Session";
import { useDispatch, useSelector } from "react-redux";
import { placeBet } from "../../Redux/Slices/betSlice";
import { toast } from "react-toastify";
import SportModal from "./SportModal";
import TiedMatch from "./TiedMatch";
import { saveTeams } from "../../Redux/Slices/SaveTeamsSlice";

const OpenMarkeet = () => {
  const {mktId, id } = useParams();
  const SOCKET_URL = "https://battlemoney-socket-api.nakshtech.info/";

  const [counteyName, setcountrydata] = useState([]);
  const [Tiedlabel, setTiedlabel] = useState([]);
  const [Complabel, setComplabel] = useState([]);
  const [ComplmarkId, setComplmarkId] = useState("");
  const [TiedmarkId, setTiedmarkId] = useState("");
  const [compType, setcompType] = useState("");
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const [socketData, setSocketData] = useState([]);
  const [realtimeData, setRealtimeData] = useState(false);
  const [count, setCount] = useState(0);
  const [CompCount, setCompCount] = useState(0);
  const [TiedCount, setTiedCount] = useState(0);
  const { eventTypeid } = useSelector((state) => state.auth.currentMatch);
  const [cashOut,setCashout]=useState(0)

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("CricketOddMatch_uid", mktId, eventTypeid,id,uid);
    cricketMatchesSocket.on("CricketOddMatch_uid_FromAPI", (data) => {
      setCashout(data.CashoutAmount)
      if (data.data && data.data.length > 0) {
        
        console.log("parseData",data)
      
        setSocketData(data.data);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);

  // useEffect(() => {
  //   // console.log("Label Before",id)
  //   const cricketMatchesSocket = io(SOCKET_URL);
  //   cricketMatchesSocket.emit("CricketOddMatch", id, eventTypeid);
  //   cricketMatchesSocket.on("CricketOddMatch_FromAPI", (data) => {
  //     // const parseData=JSON.parse(data)
  //     // console.log("parseData",parseData)
  //     if (data && data.length > 0) {
  //       const parsedData = data.map((item) => ({
  //         ...item,
  //       }));
  //       console.log("parse",parsedData)
  //       setSocketData(parsedData);
  //     }
  //     // let parseData=JSON.parse(data)
  //   });

  //   return () => {
  //     cricketMatchesSocket.disconnect();
  //   };
  // }, [id]);

  useEffect(() => {
    if (socketData && socketData.length > 0) {
      setFilterMarket(socketData, "Match Odds", setcountrydata);
      setFilterMarket(socketData, "Tied Match", setTiedmarkId, setTiedlabel);
      setFilterMarket(
        socketData,
        "Completed Match",
        setComplmarkId,
        setComplabel
      );
    }
  }, [socketData]);

  const setFilterMarket = (
    res,
    condition,
    setStateFunction,
    setlabelFunction
  ) => {
    if (Array.isArray(res)) {
      let fiteredData = res.filter((ele) => ele.marketName === condition);
      if (condition === "Match Odds") {
        setStateFunction(fiteredData[0]?.runners);
      } else {
        setStateFunction(fiteredData[0]?.marketId);
        setlabelFunction(fiteredData[0]?.runners);
      }
    } else {
      console.error("Error: Input is not an array");
    }
  };

  const SaveTeams_API = async (mname, team) => {
    dispatch(saveTeams({ id, mname, team, token }));
  };

  useEffect(() => {
    if (counteyName && counteyName.length > 0 && count < 1) {
      counteyName.forEach((runner) => {
        SaveTeams_API("Match Odds", runner.runnerName);
        SaveTeams_API("Bookmaker Market", runner.runnerName);
      });
      setCount(count + 1);
    }

    if (Tiedlabel && Tiedlabel.length > 0 && TiedCount < 1) {
      Tiedlabel.forEach((runner) => {
        SaveTeams_API("Tied Match", runner.runnerName);
      });
      setTiedCount(count + 1);
    }

    if (Complabel && Complabel.length > 0 && CompCount < 1) {
      Complabel.forEach((runner) => {
        SaveTeams_API("Completed Match", runner.runnerName);
      });
      setCompCount(count + 1);
    }
  }, [counteyName]);

  const [modal, setModal] = useState(false);
  const [marketType, setmarketType] = useState(null);
  const [betType, setbetType] = useState(null);
  const [betDetails, setbetDetails] = useState({});
  const [countryName, setcountryName] = useState(null);
  const dispatch = useDispatch();

  const handleModal = () => {
    setModal(!modal);
  };



 
  return (
    <div className="row  bg-dark ">
      <div className="betting-info">
        <button className="betting-info-btn px-2 bg-dark">OPEN MARKET</button>
        <button className="betting-info-btn px-2 bg-dark">ODDS</button>
        <button className="betting-info-btn px-2 bg-dark">BOOKMAKER</button>
        <button className="betting-info-btn px-2 bg-dark">FANCY</button>
      </div>

      <MatchOdds
        counteyName={counteyName}
        handleModal={handleModal}
        setmarketType={setmarketType}
        setbetType={setbetType}
        setbetDetails={setbetDetails}
        setRealtimeData={setRealtimeData}
        marketType={marketType}
        betType={betType}
        realtimeData={realtimeData}
        cashOut={cashOut}
      />
      {eventTypeid ==4 && (
        <>
          <BookMaker
            handleModal={handleModal}
            setmarketType={setmarketType}
            setbetType={setbetType}
            setbetDetails={setbetDetails}
            setRealtimeData={setRealtimeData}
            marketType={marketType}
            betType={betType}
            realtimeData={realtimeData}
          />
          {/* <Completed counteyName={Complabel} ComplmarkId={ComplmarkId} /> */}
          <Session
            setbetDetails={setbetDetails}
            setmarketType={setmarketType}
            setbetType={setbetType}
            handleModal={handleModal}
            setcountryName={setcountryName}
            setrealtimeData={setRealtimeData}
            marketType={marketType}
            realtimeData={realtimeData}
            betType={betType}
          />
          {/* <TiedMatch
        handleModal={handleModal}
        setmarketType={setmarketType}
        setbetType={setbetType}
        setbetDetails={setbetDetails}
        setRealtimeData={setRealtimeData}
        marketType={marketType}
        betType={betType}
        realtimeData={realtimeData}
      /> */}
          {/* <Fancy /> */}
        </>
      )}
      {modal && (
        <SportModal
          setModal={setModal}
          setbetType={setbetType}
          setmarketType={setmarketType}
          setbetDetails={setbetDetails}
          betDetails={betDetails}
          betType={betType}
          marketType={marketType}
          countryName={countryName}
          realtimeData={realtimeData}
          setRealtimeData={setRealtimeData}
        />
      )}
    </div>
  );
};

export default OpenMarkeet;
