import axios from "axios";

const baseURL = "https://battlemoney-auth-api.nakshtech.info/";

const depositHistoryAPI = axios.create({
  baseURL,
});

export const fetchUserDepositHistory = async (id, token) => {
  try {
    const response = await depositHistoryAPI.get(`buycoin_history?id=${id}`, {
      headers: {
        Authorization: token,
      },
    });
    console.log("userdepositHistory", response.data.data);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export default depositHistoryAPI;
