import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Roulette.css";
import { API } from "../../API/API";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import banner from "../../Media/Roulette/banner.png";
import undoicon from "../../Media/Roulette/undo-icon.svg";
import reseticon from "../../Media/Roulette/reset-icon.svg";
import stats from "../../Media/Roulette/stats.svg";
import { Wheel } from "react-custom-roulette";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { logoutUser } from "../../Redux/Slices/authSlice";
import { useDispatch, useSelector } from "react-redux";

// const { casinobetHistory, loading } = useSelector(
//   (state) => state.casinobetHistory
// );

const data = [
  { option: "0" },
  { option: "1" },
  { option: "2" },
  { option: "3" },
  { option: "4" },
  { option: "5" },
  { option: "6" },
  { option: "7" },
  { option: "8" },
  { option: "9" },
  { option: "10" },
  { option: "11" },
  { option: "12" },
  { option: "13" },
  { option: "14" },
  { option: "15" },
  { option: "16" },
  { option: "17" },
  { option: "18" },
  { option: "19" },
  { option: "20" },
  { option: "21" },
];

const Roulette = () => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeNumber, setPrizeNumber] = useState(0);

  const handleSpinClick = () => {
    const newPrizeNumber = Math.floor(Math.random() * data.length);
    setPrizeNumber(21);
    setMustSpin(true);
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [rouletteHistory, setRouletteHistory] = useState([]);
  const token = sessionStorage.getItem("token");
  const userId = sessionStorage.getItem("userData");
  const [getNumbers, setNumbers] = useState(0);
  const [amount, setAmount] = useState(0);
  const [roundId, setRoundId] = useState(0);
  const [periodId, setPeriodId] = useState(122684);
  const [showHistory, setShowHistory] = useState(false);
  const [resultHistory, setresultHistory] = useState([]);
  // console.log("resultHistory", resultHistory);
  const [iconStyle, setIconStyle] = useState({
    icons1: { height: "60px", width: "60px" },
  });

  const bet_Place = async () => {
    try {
      let response = await API.post(
        `/RolletBetPlace`,
        {
          uid: userId,
          periodno: roundId,
          amount: amount,
          Gameid: 3,
          number: getNumbers,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("betplace", response);
      const data = response.data.data;
      console.log("betres->", data);

      if (data == "Bet Placed.") {
        toast.success("Successfully Bet Placed");
      } else {
        toast.error(data);
      }
    } catch (e) {
      console.log("Error While Fetch BetPlace_API", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  useEffect(() => {
    let period_Wise = async () => {
      try {
        let response = await API.post(
          `PeriodWiseWinAmount`,
          {
            peroid: roundId,
            gameid: "3",
            result: "",
          },
          {
            headers: {
              Authorization: token,
            },
          }
        );
        console.log("Period Wise Win", response.data.data);
        const data = response.data.data;
        console.log("Win Period->", data);

        if (data == 200) {
          toast.success("Successfully Period Wise Win");
          console.log("Successfully win periond");
        }
      } catch (e) {
        console.log("Error While Fetch Period wise", e);
        if (
          e.response.data.showableMessage ==
          "Please login to access this resource"
        ) {
          // sessionStorage.clear();
          // dispatch(logoutUser());
          // navigate("/");
        }
      }
    };
    period_Wise();
  }, []);

  const BetResult = async () => {
    try {
      let response = await API.post(
        `/AviatorResult`,
        {
          Gameid: 3,
          Limits: 10,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("BetResult", response);
      const data = response.data.data[0];
      // console.log("Result->", data);

      if (data.length > 0) {
        setresultHistory(data);
      }
      if (data == 200) {
        toast.success("Successfully get bet result");
      }
    } catch (e) {
      console.log("Error While Fetch bet_Result_API", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  const BetHistory = async () => {
    try {
      let response = await API.post(
        `/AviatorBetHistory`,
        {
          uid: userId,
          Gameid: 3,
          Limit: 10,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      // console.log("BetHistoy", response);
      const data = response.data.data[0];
      // console.log("History->", data);
      if (data.length > 0) {
        setRouletteHistory(data);
      }
    } catch (e) {
      console.log("Error While Fetch BetHistory", e);
      if (
        e.response.data.showableMessage ==
        "Please login to access this resource"
      ) {
        sessionStorage.clear();
        dispatch(logoutUser());
        navigate("/");
      }
    }
  };

  const betHistoryClick = () => {
    BetHistory();
    setShowHistory(!showHistory);
  };

  const iconClick = (icons, amounts) => {
    setAmount(amounts);
    setIconStyle({ [icons]: { height: "60px", width: "60px" } });
  };

  const numsClick = (nums) => {
    setNumbers(nums);
    bet_Place();
  };

  useEffect(() => {
    BetResult();
  }, []);

  useEffect(() => {
    if (resultHistory.length > 0) {
      setRoundId(parseFloat(resultHistory[0].peroid) + 1);
    }
  }, [resultHistory]);

  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive history-body">
        <table>
          <thead style={{ backgroundColor: "gery" }}>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Bet Amount</th>
              <th>Win Amount</th>
              <th>Status</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody className="text-center ">
            {rouletteHistory.length > 0 ? (
              rouletteHistory?.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.peroid}</td>
                  <td>{item.Multiplayer}</td>
                  <td>{item.Amount}</td>
                  <td>{item.Winamount}</td>
                  <td className={
                              item.Betstatus === "Loss" ||
                              item.Betstatus === "Pending"
                                ? "zero-1"
                                : "success-o"
                            }>
                    {item.Betstatus}
                  </td>
                  <td>{item.edate}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="6"
                  className="text-center "
                  style={{ height: "2rem", color: "black" }}>
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );

  return (
    <>
      <div className="col-lg-10">
        <div className="row">
          <div className="col-lg-8">
            <div className="middlesectionnew">
              <div className="card middlecard">
                <div className="card-header d-block  p-1">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="heading text-white">Auto Roulette</div>
                    <div className="idnumber text-white">
                      <span>Round ID : {roundId}</span>
                    </div>
                  </div>
                </div>
                <div className="card-image mx-auto">
                  <Wheel
                    className="wheels"
                    mustStartSpinning={mustSpin}
                    prizeNumber={prizeNumber}
                    data={data}
                    backgroundColors={["#ab0a0a", "black"]}
                    textColors={["white"]}
                    outerBorderColor="#2a1414"
                    outerBorderWidth={15}
                    innerBorderColor={["#2a1414"]}
                    innerRadius={20}
                    innerBorderWidth={10}
                    radiusLineColor={["#b2a3a3"]}
                    fontSize={22}
                    perpendicularText={true}
                    onStopSpinning={() => {
                      setMustSpin(false);
                    }}
                  />
                  <button className="spin-btn mx-12" onClick={handleSpinClick}>
                    SPIN
                  </button>
                </div>
                <div className="d-none d-md-block">
                  <div className="card-button d-flex align-items-center justify-content-between p-1 ">
                    {resultHistory.map((item, index) => (
                      <ul className="buttonlist mb-0" key={index}>
                        <li>{item.Multiplayer}</li>
                      </ul>
                    ))}
                    <div className="d-flex align-items-center justify-content-between gap-5 ">
                      <div className="idseide">
                        <div className="roundid">Round ID:</div>
                        <div className="idnumber">{roundId} </div>
                      </div>
                      <button
                        className="py-2 px-3 btn playerHistorybtn text-white"
                        onClick={() => {
                          betHistoryClick();
                        }}>
                        PLAYER HISTORY
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {showHistory === true && (
                <div className="My-Bets-table_1">
                  <div className="color-game-h mt-2">
                    <div className="px-2 pt-1">
                      <p>Bet History</p>
                    </div>
                  </div>
                  {HistoryTable}
                  {/* <div className="card-table-inner-row row"></div> */}
                </div>
              )}
              {/*****************  Betting Buttons Section ************************/}

              <div className="card bottomcard mt-2 p-2">
                <div className="row mx-0 align-items-center g-3">
                  <div className="col-md-3 col-6 order-1">
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="leftside">
                        <div className="lastwin">LAST WIN</div>
                        <div className="total text-white">0</div>
                      </div>
                      <div className="rightside">
                        <div className="totalbat">LAST WIN</div>
                        <div className="total text-white">0</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 order-md-2 order-3">
                    <ul className="mb-0 middleicon px-2 py-1 rounded-pill">
                      <li
                        className="icon1"
                        style={iconStyle.icons1}
                        onClick={() => {
                          iconClick("icons1", 100);
                        }}>
                        <span className="text-white">100</span>
                      </li>
                      <li
                        className="icon2"
                        style={iconStyle.icons2}
                        onClick={() => {
                          iconClick("icons2", 500);
                        }}>
                        <span className="text-white">500</span>
                      </li>
                      <li
                        className="icon3"
                        style={iconStyle.icons3}
                        onClick={() => {
                          iconClick("icons3", 1000);
                        }}>
                        <span className="text-white">1K</span>
                      </li>
                      <li
                        className="icon4"
                        style={iconStyle.icons4}
                        onClick={() => {
                          iconClick("icons4", 5000);
                        }}>
                        <span className="text-white">5k</span>
                      </li>
                      <li
                        className="icon5"
                        style={iconStyle.icons5}
                        onClick={() => {
                          iconClick("icons5", 10000);
                        }}>
                        <span className="text-white">10k</span>
                      </li>
                      <li
                        className="icon6"
                        style={iconStyle.icons6}
                        onClick={() => {
                          iconClick("icons6", 25000);
                        }}>
                        <span className="text-white">25k</span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-3 col-6 order-md-3 order-2">
                    <div className="buttoncontent">
                      <div className="button">
                        <div className="imagebtn">
                          <img
                            src={undoicon}
                            className="w-100 h-100"
                            alt="undo-icon"
                          />
                        </div>
                        <div className="btntext">UNDO</div>
                      </div>
                      <div className="button">
                        <div className="imagebtn">
                          <img
                            src={reseticon}
                            className="w-100 h-100"
                            alt="reset-icon"
                          />
                        </div>
                        <div className="btntext">reset</div>
                      </div>
                      <div className="button">
                        <div className="imagebtn">
                          <img
                            src={stats}
                            className="w-100 h-100"
                            alt="undo-icon"
                          />
                        </div>
                        <div className="btntext">stats</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 order-4">
                    <div className="card innercard p-1 py-2">
                      <div className="roul-box">
                        <div className="roulette-ctn">
                          <audio src="/static/media/place-chip.2742bd8216d13a20d727.mp3" />
                          <div className="winning_lines">
                            <div className="wlttb">
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                            </div>
                            <div className="wlttb">
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                            </div>
                            <div id="wlttb_3" className="wlttb">
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                              <div className="ttbbetblock" />
                            </div>
                            <div id="wlrtl_0" className="wlrtl">
                              <div className="rtlbb1" />
                              <div className="rtlbb2" />
                              <div className="rtlbb3" />
                            </div>
                            <div id="wlrtl_1" className="wlrtl">
                              <div className="rtlbb1" />
                              <div className="rtlbb2" />
                              <div className="rtlbb3" />
                            </div>
                            <div id="wlrtl_2" className="wlrtl">
                              <div className="rtlbb1" />
                              <div className="rtlbb2" />
                              <div className="rtlbb3" />
                            </div>
                            <div id="wlrtl_3" className="wlrtl">
                              <div className="rtlbb1" />
                              <div className="rtlbb2" />
                              <div className="rtlbb3" />
                            </div>
                            <div id="wlrtl_4" className="wlrtl">
                              <div className="rtlbb1" />
                              <div className="rtlbb2" />
                              <div className="rtlbb3" />
                            </div>
                            <div id="wlrtl_5" className="wlrtl">
                              <div className="rtlbb1" />
                              <div className="rtlbb2" />
                              <div className="rtlbb3" />
                            </div>
                            <div id="wlrtl_6" className="wlrtl">
                              <div className="rtlbb1" />
                              <div className="rtlbb2" />
                              <div className="rtlbb3" />
                            </div>
                            <div id="wlrtl_7" className="wlrtl">
                              <div className="rtlbb1" />
                              <div className="rtlbb2" />
                              <div className="rtlbb3" />
                            </div>
                            <div id="wlrtl_8" className="wlrtl">
                              <div className="rtlbb1" />
                              <div className="rtlbb2" />
                              <div className="rtlbb3" />
                            </div>
                            <div id="wlrtl_9" className="wlrtl">
                              <div className="rtlbb1" />
                              <div className="rtlbb2" />
                              <div className="rtlbb3" />
                            </div>
                            <div id="wlrtl_10" className="wlrtl">
                              <div className="rtlbb1" />
                              <div className="rtlbb2" />
                              <div className="rtlbb3" />
                            </div>
                            <div id="wlrtl_11" className="wlrtl">
                              <div className="rtlbb1" />
                              <div className="rtlbb2" />
                              <div className="rtlbb3" />
                            </div>
                            <div id="wlcb_1" className="wlcb">
                              <div id="cbbb_0" className="cbbb" />
                              <div id="cbbb_1" className="cbbb" />
                              <div id="cbbb_2" className="cbbb" />
                              <div id="cbbb_3" className="cbbb" />
                              <div id="cbbb_4" className="cbbb" />
                              <div id="cbbb_5" className="cbbb" />
                              <div id="cbbb_6" className="cbbb" />
                              <div id="cbbb_7" className="cbbb" />
                              <div id="cbbb_8" className="cbbb" />
                              <div id="cbbb_9" className="cbbb" />
                              <div id="cbbb_10" className="cbbb" />
                              <div id="cbbb_11" className="cbbb" />
                            </div>
                            <div id="wlcb_2" className="wlcb">
                              <div id="cbbb_12" className="cbbb" />
                              <div id="cbbb_13" className="cbbb" />
                              <div id="cbbb_14" className="cbbb" />
                              <div id="cbbb_15" className="cbbb" />
                              <div id="cbbb_16" className="cbbb" />
                              <div id="cbbb_17" className="cbbb" />
                              <div id="cbbb_18" className="cbbb" />
                              <div id="cbbb_19" className="cbbb" />
                              <div id="cbbb_20" className="cbbb" />
                              <div id="cbbb_21" className="cbbb" />
                              <div id="cbbb_22" className="cbbb" />
                              <div id="cbbb_23" className="cbbb" />
                            </div>
                            <div id="d_sb" className="double_s">
                              <div id="ddss_0" className="dsblock" />
                              <div id="ddss_1" className="dsblock" />
                              <div id="ddss_2" className="dsblock" />
                              <div id="ddss_3" className="dsblock" />
                              <div id="ddss_4" className="dsblock" />
                              <div id="ddss_5" className="dsblock" />
                              <div id="ddss_6" className="dsblock" />
                              <div id="ddss_7" className="dsblock" />
                              <div id="ddss_8" className="dsblock" />
                              <div id="ddss_9" className="dsblock" />
                              <div id="ddss_10" className="dsblock" />
                              <div id="ddss_11" className="dsblock" />
                            </div>
                          </div>

                          {/* Numbers of bet */}

                          <div
                            className="zero-ctn"
                            onClick={() => {
                              numsClick(0);
                            }}>
                            0
                          </div>
                          <div className="match-odds-ctn">
                            <div className="first-column">
                              <div
                                className="col-Red col"
                                onClick={() => {
                                  numsClick(3);
                                }}>
                                <div className="single-bet">3</div>
                              </div>
                              <div
                                className="col-Black col"
                                onClick={() => {
                                  numsClick(6);
                                }}>
                                <div className="single-bet">6</div>
                              </div>
                              <div
                                className="col-Red col"
                                onClick={() => {
                                  numsClick(9);
                                }}>
                                <div className="single-bet">9</div>
                              </div>
                              <div
                                className="col-Red col"
                                onClick={() => {
                                  numsClick(12);
                                }}>
                                <div className="single-bet">12</div>
                              </div>
                              <div
                                className="col-Black col"
                                onClick={() => {
                                  numsClick(15);
                                }}>
                                <div className="single-bet">15</div>
                              </div>
                              <div
                                className="col-Red col"
                                onClick={() => {
                                  numsClick(18);
                                }}>
                                <div className="single-bet">18</div>
                              </div>
                              <div
                                className="col-Red col"
                                onClick={() => {
                                  numsClick(21);
                                }}>
                                <div className="single-bet">21</div>
                              </div>
                              <div
                                className="col-Black col"
                                onClick={() => {
                                  numsClick(24);
                                }}>
                                <div className="single-bet">24</div>
                              </div>
                              <div
                                className="col-Red col"
                                onClick={() => {
                                  numsClick(27);
                                }}>
                                <div className="single-bet">27</div>
                              </div>
                              <div
                                className="col-Red col"
                                onClick={() => {
                                  numsClick(30);
                                }}>
                                <div className="single-bet">30</div>
                              </div>
                              <div
                                className="col-Black col"
                                onClick={() => {
                                  numsClick(33);
                                }}>
                                <div className="single-bet">33</div>
                              </div>
                              <div
                                className="col-Red col"
                                onClick={() => {
                                  numsClick(36);
                                }}>
                                <div className="single-bet">36</div>
                              </div>
                            </div>
                            <div className="first-column">
                              <div
                                className="col-Black col"
                                onClick={() => {
                                  numsClick(2);
                                }}>
                                <div className="single-bet">2</div>
                              </div>
                              <div
                                className="col-Red col"
                                onClick={() => {
                                  numsClick(5);
                                }}>
                                <div className="single-bet">5</div>
                              </div>
                              <div
                                className="col-Black col"
                                onClick={() => {
                                  numsClick(8);
                                }}>
                                <div className="single-bet">8</div>
                              </div>
                              <div
                                className="col-Black col"
                                onClick={() => {
                                  numsClick(11);
                                }}>
                                <div className="single-bet">11</div>
                              </div>
                              <div
                                className="col-Red col"
                                onClick={() => {
                                  numsClick(14);
                                }}>
                                <div className="single-bet">14</div>
                              </div>
                              <div
                                className="col-Black col"
                                onClick={() => {
                                  numsClick(17);
                                }}>
                                <div className="single-bet">17</div>
                              </div>
                              <div
                                className="col-Black col"
                                onClick={() => {
                                  numsClick(20);
                                }}>
                                <div className="single-bet">20</div>
                              </div>
                              <div
                                className="col-Red col"
                                onClick={() => {
                                  numsClick(23);
                                }}>
                                <div className="single-bet">23</div>
                              </div>
                              <div
                                className="col-Black col"
                                onClick={() => {
                                  numsClick(26);
                                }}>
                                <div className="single-bet">26</div>
                              </div>
                              <div
                                className="col-Black col"
                                onClick={() => {
                                  numsClick(29);
                                }}>
                                <div className="single-bet">29</div>
                              </div>
                              <div
                                className="col-Red col"
                                onClick={() => {
                                  numsClick(32);
                                }}>
                                <div className="single-bet">32</div>
                              </div>
                              <div
                                className="col-Black col"
                                onClick={() => {
                                  numsClick(35);
                                }}>
                                <div className="single-bet">35</div>
                              </div>
                            </div>
                            <div className="first-column">
                              <div
                                className="col-Red col"
                                onClick={() => {
                                  numsClick(1);
                                }}>
                                <div className="single-bet">1</div>
                              </div>
                              <div
                                className="col-Black col"
                                onClick={() => {
                                  numsClick(4);
                                }}>
                                <div className="single-bet">4</div>
                              </div>
                              <div
                                className="col-Red col"
                                onClick={() => {
                                  numsClick(7);
                                }}>
                                <div className="single-bet">7</div>
                              </div>
                              <div
                                className="col-Black col"
                                onClick={() => {
                                  numsClick(10);
                                }}>
                                <div className="single-bet">10</div>
                              </div>
                              <div
                                className="col-Black col"
                                onClick={() => {
                                  numsClick(13);
                                }}>
                                <div className="single-bet">13</div>
                              </div>
                              <div
                                className="col-Red col"
                                onClick={() => {
                                  numsClick(16);
                                }}>
                                <div className="single-bet">16</div>
                              </div>
                              <div
                                className="col-Red col"
                                onClick={() => {
                                  numsClick(19);
                                }}>
                                <div className="single-bet">19</div>
                              </div>
                              <div
                                className="col-Black col"
                                onClick={() => {
                                  numsClick(22);
                                }}>
                                <div className="single-bet">22</div>
                              </div>
                              <div
                                className="col-Red col"
                                onClick={() => {
                                  numsClick(25);
                                }}>
                                <div className="single-bet">25</div>
                              </div>
                              <div
                                className="col-Black col"
                                onClick={() => {
                                  numsClick(28);
                                }}>
                                <div className="single-bet">28</div>
                              </div>
                              <div
                                className="col-Black col"
                                onClick={() => {
                                  numsClick(31);
                                }}>
                                <div className="single-bet">31</div>
                              </div>
                              <div
                                className="col-Red col"
                                onClick={() => {
                                  numsClick(34);
                                }}>
                                <div className="single-bet">34</div>
                              </div>
                            </div>
                          </div>
                          <div className="columns-ctn">
                            <div
                              className="columns-layer "
                              onClick={() => {
                                numsClick(42);
                              }}>
                              <div className="columns ">2:1</div>
                            </div>
                            <div
                              className="columns-layer "
                              onClick={() => {
                                numsClick(43);
                              }}>
                              <div className="columns ">2:1</div>
                            </div>
                            <div
                              className="columns-layer "
                              onClick={() => {
                                numsClick(44);
                              }}>
                              <div className="columns ">2:1</div>
                            </div>
                          </div>
                        </div>
                        <div className="dozens-layer">
                          <div className="dozens-ctn">
                            <div
                              className="dozens"
                              onClick={() => {
                                numsClick(37);
                              }}>
                              <span className="r-name">1 - 12</span>
                            </div>
                            <div
                              className="dozens"
                              onClick={() => {
                                numsClick(38);
                              }}>
                              <span className="r-name">13 - 24</span>
                            </div>
                            <div
                              className="dozens"
                              onClick={() => {
                                numsClick(39);
                              }}>
                              <span className="r-name">25 - 36</span>
                            </div>
                          </div>
                        </div>
                        <div className="last-row-ctn">
                          <div className="last-row">
                            <div
                              className="low"
                              onClick={() => {
                                numsClick(45);
                              }}>
                              <span className="r-name">1 - 18</span>
                            </div>
                            <div
                              className="even"
                              onClick={() => {
                                numsClick(41);
                              }}>
                              <span className="r-name">Even</span>
                            </div>
                            <div className="red-black">
                              <div
                                className="col-Red color-ctn"
                                onClick={() => {
                                  numsClick(47);
                                }}>
                                <span className="r-name">Red</span>
                              </div>
                              <div
                                className="col-Black color-ctn"
                                onClick={() => {
                                  numsClick(48);
                                }}>
                                <span className="r-name">Black</span>
                              </div>
                            </div>
                            <div
                              className="odd"
                              onClick={() => {
                                numsClick(40);
                              }}>
                              <span className="r-name">Odd</span>
                            </div>
                            <div
                              className="high"
                              onClick={() => {
                                numsClick(46);
                              }}>
                              <span className="r-name">19 - 36</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-block d-md-none">
                <div className="card-button d-flex align-items-center justify-content-between p-1 ">
                  <ul className="buttonlist mb-0">
                    <li>32</li>
                    <li>25</li>
                    <li>23</li>
                    <li>16</li>
                    <li>11</li>
                  </ul>
                  <div className="button">
                    <button className="py-2 px-3 btn playerHistorybtn text-white">
                      PLAYER HISTORY
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-lg-4" /> */}
          <RightSidebar />
        </div>
      </div>
    </>
  );
};
export default Roulette;
