// import { Collapse } from "bootstrap";
import React, { useEffect, useState } from "react";
import { MdOutlineStackedLineChart } from "react-icons/md";
import { MdOutlineOndemandVideo } from "react-icons/md";
import batting from "../../Media/batting.png";
import bowling from "../../Media/bowling.png";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { useParams } from "react-router-dom";
import moment from "moment";
const ScoreCard = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [score, setScore] = useState([]);
  const [ShowScore, setShowScore] = useState(false);
  const { ComName, openDate, eventTypeid } = useSelector(
    (state) => state.auth.currentMatch
  );
  const SOCKET_URL = "https://battlemoney-socket-api.nakshtech.info/";

  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetIframe", id, eventTypeid);
    cricketMatchesSocket.on("GetIframe_FromAPI", (data) => {
      if (
        data &&
        data.message === "Match score fetched." &&
        data.data &&
        Object.keys(data.data).length > 0
      ) {
        setShowScore(true);
        setScore(data.data);
      } else {
        setShowScore(false);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [id]);

  // console.log("score", score.last24ballsNew);
  return (
    <>
      {" "}
      {ShowScore && (
        <div className="">
          <div className="match-score-section mt-2 d-flex flex-column align-items-center justify-content-center">
            <div className="mt-2 match-name-div d-flex justify-content-center ">
              <button className="match-name-btn">{ComName}</button>

              <div>{/* <hr className="orange-line" /> */}</div>
              {/* <div className="orange-line">jkjbu</div> */}
            </div>

            <div className="mt-1">
              <p className="mn">
                {score?.teams[0]?.team_short_name}{" "}
                <span className="vs">vs</span>{" "}
                {score?.teams[1]?.team_short_name}
              </p>
            </div>
            <div className="in-play-main-div d-flex ">
              <button className="blue-btn">
                <MdOutlineStackedLineChart />
              </button>
              <p className="inplay-w px-3 text-center d-flex align-items-center pt-1">
                {new Date(openDate) >= new Date()
                  ? moment(openDate)
                      .tz("Asia/Kolkata")
                      .format("DD/MM/YYYY h:m A")
                  : "IN-PLAY"}
              </p>

              <button className="blue-btn">
                <MdOutlineOndemandVideo />
              </button>
            </div>
            <div className="main-score-card-div bg-black">
              <div className="row">
                <div className="col-5 ">
                  <div className="row">
                    <div className="col-3  p-2  ">
                      <div className="batting-icon d-flex justify-content-center align-items-center ms-1">
                        <img className="bat-icon-img" src={batting} alt="." />
                      </div>
                    </div>
                    <div className="col-2  d-flex justify-content-center ">
                      <p className="team-a-name mt-3">
                        {score?.teams[0]?.team_short_name}
                      </p>
                    </div>
                    <div className="col-7 d-flex flex-column justify-content-center align-items-center ">
                      <p className="team-a-name mb-0">
                        {" "}
                        {score?.teams[0]?.score.split(" ")[0]}
                        {/* -{score[2]?.wicket} */}
                      </p>
                      <p className="A-team-score mt-0 ">
                        {" "}
                        {/* {(
                          Math.floor(score[2]?.ballsdone / 6) +
                          (score[2]?.ballsdone % 6) / 10
                        ).toFixed(1)} */}
                        {score?.teams[0]?.score.split(" ")[1]}
                        OV
                        {/* & 511-10(144.0) */}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-2">
                  <button className="stumps mt-3">Stumps</button>
                </div>
                <div className="col-5">
                  <div className="row">
                    <div className="col-7 d-flex flex-column justify-content-center align-items-center  ">
                      <p className="team-a-name mb-0">
                        {/* {score[2]?.score2}-{score[2]?.wicket2} */}
                        {score?.teams[1]?.score.split(" ")[0]}
                      </p>
                      <p className="A-team-score mt-0">
                        {" "}
                        {/* {(
                          Math.floor(score[2]?.ballsdone2 / 6) +
                          (score[2]?.ballsdone2 % 6) / 10
                        ).toFixed(1)}{" "} */}
                        {score?.teams[1]?.score.split(" ")[1]}
                        OV
                        {/* & 511-10(144.0) */}
                      </p>
                    </div>
                    <div className="col-2  d-flex justify-content-center  ">
                      <p className="team-a-name mt-3">{score[2]?.team2}</p>
                    </div>

                    <div className="col-3  p-2  ">
                      <div className="batting-icon d-flex justify-content-center align-items-center ms-1">
                        <img className="bat-icon-img" src={bowling} alt="." />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <div className="mx-2 d-flex "></div>
                  {/* <div className="row">
                    <div className="col-5 batter-info d-flex flex-column justify-content-center align-items-center mt-2 ">
                      <p className="mb-0">batsmen</p>
                      <p className="mb-0">Batman1</p>
                      <p className="mb-0">Batman2</p>
                    </div>
                    <div className="col-1 batter-info d-flex flex-column justify-content-center align-items-center mt-2">
                      <p className="mb-0">RB</p>
                      <p className="mb-0">10</p>
                      <p className="mb-0">10</p>
                    </div>
                    <div className="col-1 batter-info d-flex flex-column justify-content-center align-items-center mt-2">
                      <p className="mb-0">4s</p>
                      <p className="mb-0">2</p>
                      <p className="mb-0">4</p>
                    </div>
                    <div className="col-1 batter-info d-flex flex-column justify-content-center align-items-center mt-2">
                      <p className="mb-0">6s</p>
                      <p className="mb-0">-</p>
                      <p className="mb-0">-</p>
                    </div>
                    <div className="col-1 batter-info d-flex flex-column justify-content-center align-items-center mt-2"></div>
                    <div className="col-3 batter-info d-flex flex-column justify-content-center align-items-center mt-2">
                      <p className="mb-0">Strike Rate</p>
                      <p className="mb-0">-</p>
                      <p className="mb-0">-</p>
                    </div>
                  </div> */}
                </div>
                <div className="col-md-4 d-flex justify-content-center  ">
                  <div className=" over-ball-div d-block  ">
                    <div className=" mx-2 d-flex justify-content-between align-items-center mt-3">
                      {score?.last24ballsNew.map((digit, index) => (
                        <div
                          className={`${
                            digit.score_card === "ww" ||
                            digit.score_card === "wd"
                              ? "red-info"
                              : digit.score_card === "0"
                              ? "no-run-info"
                              : digit.score_card === "1"
                              ? "single-info"
                              : digit.score_card === "4"
                              ? "six-info"
                              : digit.score_card === "6"
                              ? "six-info"
                              : "single-info"
                          }`}
                          key={index}
                        >
                          {digit.score_card}{" "}
                          {/* Assuming digit is an object with a score_card property */}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  {/* <div className="row">
                    <div className="col-5 batter-info d-flex flex-column justify-content-center align-items-center mt-2 ">
                      <p className="mb-0">batsmen</p>
                      <p className="mb-0">Batman3</p>
                      <p className="mb-0">Batman4</p>
                    </div>
                    <div className="col-1 batter-info d-flex flex-column justify-content-center align-items-center mt-2">
                      <p className="mb-0">RB</p>
                      <p className="mb-0">10</p>
                      <p className="mb-0">10</p>
                    </div>
                    <div className="col-1 batter-info d-flex flex-column justify-content-center align-items-center mt-2">
                      <p className="mb-0">4s</p>
                      <p className="mb-0">2</p>
                      <p className="mb-0">4</p>
                    </div>
                    <div className="col-1 batter-info d-flex flex-column justify-content-center align-items-center mt-2">
                      <p className="mb-0">6s</p>
                      <p className="mb-0">-</p>
                      <p className="mb-0">-</p>
                    </div>
                    <div className="col-1 batter-info d-flex flex-column justify-content-center align-items-center mt-2"></div>
                    <div className="col-3 batter-info d-flex flex-column justify-content-center align-items-center mt-2">
                      <p className="mb-0">Strike Rate</p>
                      <p className="mb-0">-</p>
                      <p className="mb-0">-</p>
                    </div>
                  </div> */}
                </div>
              </div>
              {score && score[0]?.cb && (
                <div className="lead d-flex justify-content-center">
                  <p> {score[0]?.cb}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="px-1 d-lg-none  d- block">
        <div className="TV-outer-border px-3 py-2">
          <div className="live-tv1">
            <div className="d-flex justify-content-between tv-card pb-0 mb-0 p-1">
              <iframe
                allowFullScreen={true}
                style={{
                  width: "100%",
                  height: "fit-content",
                  border: "none",
                  opacity: "1",
                  visibility: "visible",
                  color: "white ",
                }}
                id="frame_video_2"
                // src={`https://stream.1ex99.in/tv2?EventId=${id}`}
                src={`https://betexch.online/AViframe/${id}`}
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScoreCard;
