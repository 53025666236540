import React, { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { io } from "socket.io-client";
import BookTab from "./Table/BookTab";
import { fetchbetStackData } from "../../Redux/api/betStackAPI";
import { useDispatch, useSelector } from "react-redux";
import OddsTable from "./Table/OddsTable";
import BookTable from "./Table/BookTable";
import { API } from "../../API/API";

const BookMaker = ({
  handleModal,
  setbetType,
  setmarketType,
  setbetDetails,
  setRealtimeData,
  marketType,
  betType,
  realtimeData,
}) => {
  const dispatch = useDispatch();
  const { mktId, id } = useParams();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const [bm, setBm] = useState([]);
  const [dataValue, setDataValue] = useState("");
  const [sessionMkt, setsessionMkt] = useState("");
  const { eventTypeid } = useSelector((state) => state.auth.currentMatch);
  const [t1, setT1] = useState([]);
  const [t2, setT2] = useState([]);
  const [t3, setT3] = useState([]);
  const [t4, setT4] = useState([]);
  const [showMatchOdds, setshowMatchOdds] = useState(false);
  const [drawt1, setdrawT1] = useState([]);
  const [drawt2, setdrawT2] = useState([]);
  const [sus, setSus] = useState("0.0");
  const [sus1, setSus1] = useState("0.0");
  const [sus2, setSus2] = useState("0.0");

  const SOCKET_URL = "https://battlemoney-socket-api.nakshtech.info/";

  // useEffect(() => {
  //   const cricketMatchesSocket = io(SOCKET_URL);
  //   cricketMatchesSocket.emit("GetBookMarket", id, eventTypeid);
  //   cricketMatchesSocket.on("GetBookMarket_FromAPI", (data) => {
  //     if (Array.isArray(data) && data.length > 0) {
  //       setsessionMkt(data[0].marketId);
  //     }
  //   });
  //   return () => {
  //     cricketMatchesSocket.disconnect();
  //   };
  // }, [eventTypeid]);

  async function BookId_API(){
    try{
        let res=await API.get(`getBookmakerMarketID?EventTypeID=${eventTypeid}&EventID=${id}`)
        console.log("res",res)
       
                setsessionMkt(res.data.data);
        

    }catch(e){
      console.log(e)
    }
  }


  useEffect(()=>{
    BookId_API()
  },[])
  useEffect(() => {
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("CricketBookMarket2", sessionMkt, eventTypeid);
    cricketMatchesSocket.on("CricketBookMarket2_FromAPI", (data) => {
      if (Array.isArray(data) && data.length > 0) {
        let res = data.map((item) => JSON.parse(item));
        // console.log("Bookmaker_data2", res);
        setshowMatchOdds(true);

        setT1(res[0]?.runners[0]?.ex?.availableToBack || []);
        setT2(res[0]?.runners[0]?.ex?.availableToLay || []);
        setT3(res[0]?.runners[1]?.ex?.availableToBack || []);
        setT4(res[0]?.runners[1]?.ex?.availableToLay || []);
        setdrawT1(res[0]?.runners[2]?.ex?.availableToBack || []);
        setdrawT2(res[0]?.runners[2]?.ex?.availableToLay || []);
        setSus(res[0]?.runners[0] || {});
        setSus1(res[0]?.runners[1] || {});
        setSus2(res[0]?.runners[2] || {});
        setBm(res[0] || {});
      } else {
        setshowMatchOdds(false);
      }
    });
    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, [sessionMkt]);

  function stop() {
    document.getElementById("marquee1").stop();
  }

  function start() {
    document.getElementById("marquee1").start();
  }

  const checkValid = () => {
    if (
      realtimeData &&
      marketType === "Bookmaker Market" &&
      (sus?.selectionId !== dataValue?.selId ||
        sus1?.selectionId !== dataValue?.selId ||
        sus2?.selectionId !== dataValue?.selId)
    ) {
      // console.log("valid1", dataValue, realtimeData);
      if (betType === "back") {
        if (
          t1[0]?.price == dataValue?.valueOf ||
          t3[0]?.price == dataValue?.valueOf ||
          drawt1[0]?.price == dataValue?.valueOf
        ) {
          return;
        } else {
          // console.log("valid2", t1[0]?.price);
          setRealtimeData(false);
          setDataValue({});
        }
      } else if (betType === "lay") {
        if (
          t2[0]?.price !== dataValue?.valueOf ||
          t4[0]?.price !== dataValue?.valueOf ||
          drawt2[0]?.price !== dataValue?.valueOf
        ) {
          return;
        } else {
         
          setRealtimeData(false);
          setDataValue({});
        }
      }
    }
  };

  useEffect(() => {
    checkValid();
  }, [realtimeData, dataValue, t1]);

  useEffect(() => {
    dispatch(
      fetchbetStackData({
        userId: uid,
        mId: id,
        matchType: "Bookmaker Market",
        token,
      })
    );
  }, [dispatch, id]);

  return (
    <>
    {showMatchOdds&&(
      <div className="row  bg-dark ">
        <div className="Match-ods-h d-flex align-items-center">
          <p className="mx-2 mt-3 d-flex">
            BOOKMAKER FAST BET(0% COMMISION){" "}
            <div className="green-live-dot ms-2 mt-2"></div>
          </p>
        </div>
        <div className="row back-lay-row  bg-dark ">
          <div className="col-7 "></div>
          <div className="col-5 ">
            <button className="back ">Back</button>
            <button className="lay ms-1">Lay</button>
          </div>
        </div>
        <div className="betting-team-info">
          {Array.isArray(bm.runners) && bm.runners.length > 0 && (
            <>
              <BookTable
                tabName={sus}
                runnerName={sus1.runnerName}
                back={t1}
                lay={t2}
                sus={sus}
                handleModal={handleModal}
                setmarketType={setmarketType}
                setbetType={setbetType}
                setbetDetails={setbetDetails}
                setRealtimeData={setRealtimeData}
                realtimeData={realtimeData}
                setDataValue={setDataValue}
                // stakefunction={setOdd1Stack}
              />
              <BookTable
                tabName={sus1}
                runnerName={sus1.runnerName}
                back={t3}
                lay={t4}
                sus={sus1}
                handleModal={handleModal}
                setmarketType={setmarketType}
                setbetType={setbetType}
                setbetDetails={setbetDetails}
                setRealtimeData={setRealtimeData}
                realtimeData={realtimeData}
                setDataValue={setDataValue}
                // stakefunction={setOdd2Stack}
              />
            </>
          )}

          {Array.isArray(bm.runners) && bm.runners.length > 2 && (
            <>
              <BookTable
                tabName={sus2}
                runnerName=""
                back={drawt1}
                lay={drawt2}
                sus={sus2}
                handleModal={handleModal}
                setmarketType={setmarketType}
                setbetType={setbetType}
                setbetDetails={setbetDetails}
                setRealtimeData={setRealtimeData}
                setDataValue={setDataValue}
                // stakefunction={setOdd3Stack}
              />
            </>
          )}

          {/* {bm?.map((item, index) => (
                <BookTab
                  key={index}
                
                  tabData={item}
                  handleModal={handleModal}
                  setmarketType={setmarketType}
                  setbetType={setbetType}
                  setbetDetails={setbetDetails}
                  setRealtimeData={setRealtimeData}
                  setDataValue={setDataValue}
                  
                />
              ))} */}

          <marquee
            id="marquee1"
            direction="left"
            scrollamount="4"
            onMouseOver={stop}
            onMouseOut={start}
            className="text-danger"
          >
            {bm?.rem}
          </marquee>
        </div>
      </div>)}
    </>
  );
};

export default memo(BookMaker);
