import React, { useState } from 'react';

export default function CheckOdds() {
  const [initialLayPosition, setInitialLayPosition] = useState('');
  const [backAmount, setBackAmount] = useState('');
  const [partialCashOutAmount, setPartialCashOutAmount] = useState('');

  const calculatePartialCashOut = () => {
    if (!initialLayPosition || !backAmount) {
      alert('Please enter both Initial Lay Position and Back Amount.');
      return;
    }

    const initialLay = parseFloat(initialLayPosition);
    const back = parseFloat(backAmount);

    if (isNaN(initialLay) || isNaN(back)) {
      alert('Please enter valid numbers for Initial Lay Position and Back Amount.');
      return;
    }

    if (back >= initialLay) {
      alert('Back Amount must be less than Initial Lay Position.');
      return;
    }

    const partialCashOut = ((initialLay-0.02)  - back) / (initialLay - 1.04);
    setPartialCashOutAmount(partialCashOut.toFixed(3));
  };

  return (
    <div>
      <h2>Partial Cash Out Calculator</h2>
      <label>
        Initial Lay Position:
        <input
          type="number"
          value={initialLayPosition}
          onChange={(e) => setInitialLayPosition(e.target.value)}
        />
      </label>
      <br />
      <label>
        Back Amount:
        <input
          type="number"
          value={backAmount}
          onChange={(e) => setBackAmount(e.target.value)}
        />
      </label>
      <br />
      <button onClick={calculatePartialCashOut}>Calculate Partial Cash Out</button>
      <br />
      {partialCashOutAmount && (
        <div>
          Partial Cash Out Amount: {partialCashOutAmount}
        </div>
      )}
    </div>
  );
}











//  const [isBackBet, setIsBackBet] = useState(true);
//   const [amount, setAmount] = useState(0);
//   const [odds, setOdds] = useState(0);
//   const [returnAmount, setReturnAmount] = useState(0);


//   const data = [
//     { Team1: "INDIA", Amount: "100", Type: "Back", Multiplyer: "2.0" },
//   ];

//   //,
//   // {"Team1": "INDIA" , "Amount": "100", "Type": "Lay", "Multiplyer": "2.20" }
//   // {"Team1": "INDIA" , "Amount": "100", "Type": "Lay", "Multiplyer": "2.20" },
//   // ,{"Team1": "INDIA" , "Amount": "200", "Type": "Lay", "Multiplyer": "2.20" },
//   let back = 1.9;
//   let lay = 2.3;

//   let AmountBack = 0;

//   let AmountLay = 0;

//   let MultiplyerBack = 0;

//   let MultiplyerLay = 0;

//   let TotalAmountBack = 0;

//   let TotalAmountLay = 0;

//   let TotaBack = 0;

//   let TotaLay = 0;

//   const jsonDataString = JSON.stringify(data);
//   // console.log("jsonDataString", jsonDataString);

//   const parsedData = JSON.parse(jsonDataString);
//   // console.log("parsedData", parsedData);

//   parsedData.forEach((item) => {
//     // console.log("Amount:", item.Amount);
//     // console.log("Type:", item.Type);
//     if (item.Type == "Back") {
//       TotaBack = parseFloat(TotaBack) + 1;
//       TotalAmountBack =
//         parseFloat(TotalAmountBack) +
//         parseFloat(item.Amount) * parseFloat(item.Multiplyer);
//       AmountBack = parseFloat(AmountBack) + parseFloat(item.Amount);
//       MultiplyerBack = parseFloat(MultiplyerBack) + parseFloat(item.Multiplyer);
//     } else {
//       TotaLay = TotaLay + 1;
//       TotalAmountLay =
//         parseFloat(TotalAmountLay) +
//         parseFloat(item.Amount) * parseFloat(item.Multiplyer);
//       AmountLay = parseFloat(AmountLay) + parseFloat(item.Amount);
//       MultiplyerLay = parseFloat(MultiplyerLay) + parseFloat(item.Multiplyer);
//     }
//   });

//   let TotalbackAM = parseFloat(TotalAmountBack) / parseFloat(AmountBack);
//   let TotallayAM = parseFloat(TotalAmountLay) / parseFloat(AmountLay);

//   // console.log("TotalAmountBackloop", AmountBack);
//   // console.log("TotalAmountLayloop", AmountLay);
//   // console.log("RAteback", TotalbackAM );
//   // console.log("RAtelay", TotallayAM );

//   // console.log("Diffrence", ((TotalbackAM-lay)*AmountBack)-((TotallayAM-back)*AmountLay));
//   let bbbb = (back - TotalbackAM) * AmountBack;
//   let lllll = TotallayAM * AmountLay;
//   console.log("Diffrenceback", bbbb);
//   // console.log("Diffrencelay",((TotallayAM-lay)*AmountLay));
//   // console.log("Diffrencelay",((TotallayAM-lay)*AmountLay));

//   // useEffect(() => {
//   //   forEach{
//   //     parsedData.Amount
//   //   }

//   // }
//   // , []);

//   let marketType = "matchOdds";
//   let sports = "cricket";
//   const plExposures = [77, -100];

//   plExposures.sort((a, b) => b.exposure - a.exposure);

//   var odds = [1.2, 1.22];
//   odds.sort((a, b) => b.exposure - a.exposure);
//   var oppositeTeam = {
//     back1: parseFloat("2.26"),
//     back2: parseFloat("2.25"),
//     back3: parseFloat("2.24"),
//     backSize1: parseFloat("2500"),
//     backSize2: parseFloat("2900"),
//     backSize3: parseFloat("3500"),
//     lay1: parseFloat("2.3"),
//     lay2: parseFloat("2.31"),
//     lay3: parseFloat("2.32"),
//     laySize1: parseFloat("3500"),
//     laySize2: parseFloat("4000"),
//     laySize3: parseFloat("3000"),
//     exposure: "-100",
//     runnerName: "Rajasthan Royals",
//   };

//   var sameTeam = {
//     back1: parseFloat("1.28"),
//     back2: parseFloat("1.29"),
//     back3: parseFloat("1.30"),
//     backSize1: parseFloat("2500"),
//     backSize2: parseFloat("2900"),
//     backSize3: parseFloat("3500"),
//     lay1: parseFloat("1.78"),
//     lay2: parseFloat("1.79"),
//     lay3: parseFloat("1.80"),
//     laySize1: parseFloat("3500"),
//     laySize2: parseFloat("4000"),
//     laySize3: parseFloat("3000"),
//     exposure: "77",
//     runnerName: "Gujarat Titans",
//   };

//   console.log("Gujarat Titans");
//   var calculationMap = {
//     pandl: -Infinity,
//     amount: 0,
//     oddDecided: 0,
//     type: "back",
//     oddRate: 0,
//   };

//   function calculatePandL(oddType, OddSize) {
//     if (oddType == "back1" || oddType == "back2" || oddType == "back3") {
//       ///pankaj back code to work

//       var pandlInternal =
//         (oppositeTeam.exposure +
//           oppositeTeam[oddType] * sameTeam.exposure -
//           sameTeam.exposure) /
//         oppositeTeam.oddType;
//       var amountInternal = sameTeam.exposure - pandlInternal;


//       if (calculationMap.pandl < pandlInternal && amountInternal > 0) {
//         calculationMap.pandl = pandlInternal;
//         calculationMap.amount = amountInternal;
//         calculationMap.oddDecided = oppositeTeam[oddType];
//         calculationMap.type = "back";
//         calculationMap.oddRate = oppositeTeam[OddSize];
//         calculationMap.runnerName = sameTeam.runnerName;
//       }
//       console.log("if", pandlInternal);
//     } else {


//       ///pankaj lay code to work
//       var pandlInternal =
//         (sameTeam.exposure +
//           sameTeam[oddType] * oppositeTeam.exposure -
//           oppositeTeam.exposure) /
//         sameTeam[oddType];
//       var amountInternal = pandlInternal - oppositeTeam.exposure;
//       console.log("else", pandlInternal);


//       if (calculationMap.pandl < pandlInternal && amountInternal > 0) {
//         calculationMap.pandl = pandlInternal;
//         calculationMap.amount = amountInternal;
//         calculationMap.oddDecided = sameTeam[oddType];
//         calculationMap.type = "lay";
//         calculationMap.oddRate = sameTeam[OddSize];
//         calculationMap.runnerName = sameTeam.runnerName;
//       }
//     }
//   }


//   calculatePandL("back1", "backSize1");
//   calculatePandL("back2", "backSize2");
//   calculatePandL("back3", "backSize3");
//   calculatePandL("lay1", "laySize1");
//   calculatePandL("lay2", "laySize2");
//   calculatePandL("lay3", "laySize3");

//   console.log("pcashamount", parseFloat(calculationMap.amount).toFixed(2));
//   //   if (calculationMap.pandl == -Infinity) {
//   //     toast({
//   //       title: "You are not eligible for cashout",
//   //       status: "error",
//   //       duration: 3000,
//   //       isClosable: true,
//   //     });
//   //     return;
//   // };

//   return <div>checkodds</div>;
// }
