import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const baseURL = "https://battlemoney-match-api.nakshtech.info/";

const API = axios.create({
  baseURL,
});

export const fetchbetStackData = createAsyncThunk(
  "betStack/fetchData",
  async ({ userId, mId, matchType, token }) => {
    try {
      const res = await API.post(
        "/PlaceBetCalculaterDB_New_1",
        {
          uid: userId,
          matchid: mId,
          mname: matchType,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );

      // console.log("betStack",matchType,res)
      return res.data.data[0];
    } catch (error) {
      throw error;
    }
  }
);
