import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const API = axios.create({
  baseURL: "https://battlemoney-auth-api.nakshtech.info/",
});

export const competionDetails = createAsyncThunk('match/competition', async ({ eventId }, { rejectWithValue }) => {
  try {
    const response = await API.get(`GetAllCompetitionsData?EventTypeID=${eventId}`);
    return response.data.data; 
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const competitionSlice = createSlice({
  name: 'competition',
  initialState: {
    error: null,
    competitions:[],
  },
  reducers: {}, 
  extraReducers: (builder) => {
    builder
      .addCase(competionDetails.fulfilled, (state, action) => {
        state.error = null;
        state.competitions = action.payload;
      })
      .addCase(competionDetails.rejected, (state, action) => {
        state.error = action.payload ? action.payload : "Unknown error";
      });
  },
});

export default competitionSlice.reducer;
