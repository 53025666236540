import React, { useEffect, useState } from "react";
import RightSidebar from "../RightSidebar/RIghtSidebar";
import c from "../../Media/2HH.png";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCasinobetHistory } from "../../Redux/Slices/casinobetHistory";
import { io } from "socket.io-client";
import c1 from "../../Media/1.jpg";
import c2 from "../../Media/2.jpg";
import c3 from "../../Media/3.jpg";
import c4 from "../../Media/4.jpg";
import c5 from "../../Media/5.jpg";
import c6 from "../../Media/6.jpg";
import c7 from "../../Media/7.jpg";
import c8 from "../../Media/8.jpg";
import c9 from "../../Media/9.jpg";
import c10 from "../../Media/10.jpg";
import c11 from "../../Media/11.jpg";
import c12 from "../../Media/12.jpg";
import c13 from "../../Media/13.jpg";
import Rules_Modal from "../Rules_Modal";
import { rulesData } from "../../Redux/Slices/rulesSlice";
import Modal from "../TeenPatti/Modal";
import { API } from "../../API/API";
import { FaTrophy } from "react-icons/fa";
import { MdCancel } from "react-icons/md";
import a from "../../Media/10SS.png";
import b from "../../Media/3SS.png";
import { fetchuserBalance } from "../../Redux/Slices/userBalanceSlice";

function DragonTiger2() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  const imageImports = [c1, c2, c3, c4, c5, c6, c7, c8, c9, c10, c11, c12, c13];

  const [name, setName] = useState("");
  const [results, setResults] = useState([]);
  const [reqRes, setreqRes] = useState({ t1: [], t2: [] });
  const [showresultModal, setShowresultModal] = useState(false);
  const [showDataModal, setShowdataModal] = useState(false);
  const [rulesModal, setRulesModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [betDetails, setbetDetails] = useState({});
  const [betno, setBetNum] = useState(0);
  const { casinobetHistory, loading } = useSelector(
    (state) => state.casinobetHistory
  );

  const [midRound, setMidRound] = useState(0);
  const [casinoGameResult, setCasinoGameResult] = useState([]);

  const CasinoGameResult_API = async (mid) => {
    try {
      const response = await API.post("/GetCasinoGameResultData", {
        mid: mid,
        gameid: id,
      });
      // console.log("CasinoGameResult dragon:", response.data.data[0]);
      setCasinoGameResult(response.data.data[0]);
    } catch (error) {
      console.error("Error CasinoGameResult_API", error);
    }
  };

  const ResultsModal = () => {
    return (
      <>
        <div
          onClick={() => {
            setShowresultModal(false);
          }}
          className="Results-modal-wrapper"></div>
        <div className="teen-results-modal-container bg-dark">
          <div className="results-modal-h d-flex justify-content-between pt-2">
            <div>Round ID: {midRound}</div>
            <MdCancel
              className="cross-icon-result me-2"
              onClick={() => {
                setShowresultModal(false);
              }}
            />
          </div>
          <div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div ">
                <div className="text-center">Player A</div>
                <div className="d-flex">
                {casinoGameResult[0] && (
                  <div className="gamecard-div mx-1" key={casinoGameResult[0].id}>
                    <img
                      src={casinoGameResult[0].image}
                      className="img-fluid"
                      alt={`Player A Card 0`}
                    />
                  </div>
                )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-2">
              <div className="player-a-div  ">
                <div className="d-flex justify-content-between">
                  <div className="text-center">Player B</div>
                  <FaTrophy className="trophy-icon" />
                </div>

                <div className="d-flex">
                {casinoGameResult[1] && (
                  <div className="gamecard-div mx-1" key={casinoGameResult[1].id}>
                    <img
                      src={casinoGameResult[1].image}
                      className="img-fluid"
                      alt={`Player B Card 1`}
                    />
                  </div>
                )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const SOCKET_URL = "https://battlemoney-socket-api.nakshtech.info/";

  useEffect(() => {
    // console.log("Odd socket before");
    const cricketMatchesSocket = io(SOCKET_URL);
    cricketMatchesSocket.emit("GetCasinoGameSocket", id);

    cricketMatchesSocket.on("GetCasinoGameSocket_FromAPI", (data) => {
      // console.log("TeenPatti socket1==>", data);
      if (data && data.length > 0) {
        const parsedData = data.map((item) => ({
          ...item,
          reqResponse: JSON.parse(item.reqResponse),
          resultUrlResponse: JSON.parse(item.resultUrlResponse),
        }));

        // console.log("parsed", parsedData[0].reqResponse);
        // console.log("parsed_result", parsedData[0].resultUrlResponse);
        setResults(parsedData[0].resultUrlResponse);
        setreqRes(parsedData[0].reqResponse);
        setName(parsedData[0].name);
      }
    });

    return () => {
      cricketMatchesSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    dispatch(fetchCasinobetHistory({ uid, token,id }));
  }, [dispatch, uid,reqRes?.t1[0]?.mid]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      dispatch(fetchCasinobetHistory({ uid, token, id }));
      dispatch(fetchuserBalance({ id: uid, token }));
    }, 10000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [dispatch, uid, reqRes?.t1[0]?.mid]);
  useEffect(() => {
    dispatch(rulesData());
  }, [dispatch]);
  const HistoryTable = (
    <>
      <div className="table-h-div row table-responsive">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Period</th>
              <th>Multiplier</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Remark</th>
              <th>Card</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {loading ? (
              <tr>
                <td colSpan="6">Loading ...</td>
              </tr>
            ) : casinobetHistory.length > 0 ? (
              casinobetHistory.map((item, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.period}</td>
                  <td>{item.multipler}</td>
                  <td>{item.amount}</td>
                  <td>{item.dd}</td>
                  <td className={item.remark === "Loss" ? "zero" : "success-1"}>
                    {item.remark}
                  </td>
                  <td>{item.card}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center">
                  No Data
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );


  
  const handleModal = (value,nat,no) => {
 
    setbetDetails({
      ...betDetails,
      gameid: id,
      multipler: value,
      period:0,
      nat: nat,
      number:no
    });
    setShowModal(true);
  };

  return (
    <div className="col-lg-10 home">
      <div className="row">
        <div className="col-lg-8 px-2 mt-1">
          <div className="hei">
            <div className="teenpatti-game-h d-flex justify-content-between  ">
              <div className=" px-2">
                <p className=" mt-1 ">{name}</p>
              </div>
              <div>
                <p className="mt-1 px-2">
                  Round ID: {reqRes?.t1[0]?.mid || 0.0} | Left Time:{" "}
                  {reqRes?.t1[0]?.autotime || 0} |{" "}
                  <span onClick={() => setRulesModal(true)}>Rules</span>
                </p>
              </div>
            </div>
            <div className="card  teenpatti-game-card p-1 mt-1">
              <div className="row">
                <div className="col-10 ">
                  <div className="" style={{ height: "170%", width: "120%" }}>
                    <iframe
                      height="100%"
                      width="100%"
                      src={`https://battlemoney.live/iframe/${id}`}
                      title=""></iframe>
                  </div>
                </div>
                <div className="col-2 d-flex justify-content-end">
                  <label className="switch">
                    <input type="checkbox" />
                    <span className="slider round"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="te">
              <div className="row">
                <div className="col-md-12">
                  <div className="">
                    <div className="d-t-box m-b-10 m-t-10">
                      <div className="row">
                        <div className="col-md-9">
                          <div className="row">
                            <div className="col-md-5 text-center textss">
                              <b> {reqRes?.t2[0]?.rate}</b>{" "}
                              <div className="m-t-10">
                                {reqRes?.t2[0]?.gstatus == 0 ? (
                                  <button className="text-uppercase btn-theme low-high-btn suspended textss">
                                    <i className="fa fa-lock"></i>
                                  </button>
                                ) : (
                                  <button
                                    className="text-uppercase btn-theme low-high-btn suspended textss"
                                    onClick={() => {
                                      // setShowModal(true);
                                      // setBetNum(1);
                                      handleModal(reqRes?.t2[0]?.rate,reqRes?.t2[0]?.nat,1);

                                    }}>
                                    <b> {reqRes?.t2[0]?.nat}</b>
                                  </button>
                                )}
                                <span
                                  className="d-block m-t-5"
                                  style={{ color: "white" }}>
                                  0
                                </span>
                              </div>
                            </div>{" "}
                            <div className="col-md-2 text-center textss">
                              <b>{reqRes?.t2[2]?.rate}</b>{" "}
                              <div className="m-t-10">
                                {reqRes?.t2[2]?.gstatus == 0 ? (
                                  <button className="text-uppercase btn-theme low-high-btn suspended textss">
                                    <i className="fa fa-lock"></i>
                                  </button>
                                ) : (
                                  <button
                                    className="text-uppercase btn-theme low-high-btn suspended textss"
                                    onClick={() => {
                                      // setShowModal(true);
                                      // setBetNum(3);
                                      handleModal(reqRes?.t2[2]?.rate,reqRes?.t2[2]?.nat,3);

                                    }}>
                                    <b>{reqRes?.t2[2]?.nat}</b>
                                  </button>
                                )}
                                <span
                                  className="d-block m-t-5"
                                  style={{ color: "white" }}>
                                  0
                                </span>
                              </div>
                            </div>{" "}
                            <div className="col-md-5 text-center textss">
                              <b>{reqRes?.t2[1]?.rate}</b>{" "}
                              <div className="m-t-10">
                                {reqRes?.t2[1]?.gstatus == 0 ? (
                                  <button className="text-uppercase btn-theme low-high-btn suspended textss">
                                    <i className="fa fa-lock"></i>
                                  </button>
                                ) : (
                                  <button
                                    className="text-uppercase btn-theme low-high-btn suspended textss"
                                    onClick={() => {
                                      // setShowModal(true);
                                      // setBetNum(2);
                                      handleModal(reqRes?.t2[1]?.rate,reqRes?.t2[1]?.nat,2);

                                    }}>
                                    <b>{reqRes?.t2[1]?.nat}</b>
                                  </button>
                                )}
                                <span
                                  className="d-block m-t-5"
                                  style={{ color: "white" }}>
                                  0
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>{" "}
                        <div className="col-md-3 pair-block text-center textss">
                          <b>{reqRes?.t2[3]?.rate}</b>{" "}
                          <div className="m-t-10">
                            {reqRes?.t2[3]?.gstatus == 0 ? (
                              <button className="text-uppercase btn-theme low-high-btn suspended textss">
                                <i className="fa fa-lock"></i>
                              </button>
                            ) : (
                              <button
                                className="text-uppercase btn-theme low-high-btn suspended textss"
                                onClick={() => {
                                  // setShowModal(true);
                                  // setBetNum(4);

                                  handleModal(reqRes?.t2[3]?.rate,reqRes?.t2[3]?.nat,4);

                                }}>
                                <b>{reqRes?.t2[3]?.nat}</b>
                              </button>
                            )}
                            <span
                              className="d-block m-t-5"
                              style={{ color: "white" }}>
                              0
                            </span>
                          </div>
                        </div>{" "}
                        <div className="col-md-12 m-t-10 text-right textss">
                          <span className="m-r-10">
                            <b>Min: </b>
                            <span>{reqRes?.t2[0]?.min}</span>
                          </span>{" "}
                          <span>
                            <b>Max: </b>
                            <span>{reqRes?.t2[0]?.max}</span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <br></br>

                    <div className="row row5">
                      <div className="col-md-6">
                        <div className="d-t-box m-b-10">
                          <div className="row">
                            <div className="col-12">
                              <h4 className="m-b-0 text-center text-uppercase textss">
                                {reqRes?.t2[4]?.nat.split(" ")[0]}
                              </h4>
                            </div>{" "}
                            {reqRes?.t2?.slice(4, 8)?.map((item, index) => (
                              <div
                                className="col-md-6 text-center textss"
                                key={index}>
                                <b>{item.rate}</b>{" "}
                                <div className="m-t-10">
                                  {item.gstatus == 0 ? (
                                    <button className="text-uppercase btn-theme low-high-btn suspended textss">
                                      <i className="fa fa-lock"></i>
                                    </button>
                                  ) : (
                                    <button
                                      className="text-uppercase btn-theme low-high-btn suspended textss"
                                      onClick={() => {
                                        // setShowModal(true);
                                        // setBetNum(index + 1);
                                        handleModal(item.rate,item.nat,index+5);

                                      }}>
                                      <b> {item.nat.split(" ")[1]}</b>
                                    </button>
                                  )}
                                  <span
                                    className="d-block m-t-5"
                                    style={{ color: "white" }}>
                                    0
                                  </span>
                                </div>
                              </div>
                            ))}
                            {/* <div className="col-md-6 text-center textss">
                              <b>1.79</b>{" "}
                              <div className="m-t-10">
                                <button className="text-uppercase btn-theme low-high-btn suspended textss">
                                  <b>Odd</b>
                                </button>{" "}
                                <span
                                  className="d-block m-t-5"
                                  style={{ color: "white" }}
                                >
                                  0
                                </span>
                              </div>
                            </div>{" "} */}
                            <div className="col-md-12 m-t-10 text-right textss">
                              <span className="m-r-10">
                                <b>Min: </b>
                                <span>{reqRes?.t2[4]?.min}</span>
                              </span>{" "}
                              <span>
                                <b>Max: </b>
                                <span>{reqRes?.t2[4]?.max}</span>
                              </span>
                            </div>{" "}
                          </div>
                        </div>
                      </div>{" "}
                      <div className="col-md-6">
                        <div className="d-t-box m-b-10">
                          <div className="row">
                            <div className="col-12">
                              <h4 className="m-b-0 text-center text-uppercase textss">
                                {reqRes?.t2[21]?.nat.split(" ")[0]}
                              </h4>
                            </div>{" "}
                            {reqRes?.t2?.slice(21, 25)?.map((item, index) => (
                              <div
                                className="col-md-6 text-center textss"
                                key={index}>
                                <b>{item.rate}</b>{" "}
                                <div className="m-t-10">
                                  {item.gstatus == 0 ? (
                                    <button className="text-uppercase btn-theme low-high-btn suspended textss">
                                      <i className="fa fa-lock"></i>
                                    </button>
                                  ) : (
                                    <button
                                      className="text-uppercase btn-theme low-high-btn suspended textss"
                                      onClick={() => {
                                        // setShowModal(true);
                                        // setBetNum(index + 1);

                                        handleModal(item.rate,item.nat,index+22);

                                      }}>
                                      <b> {item.nat.split(" ")[1]}</b>
                                    </button>
                                  )}
                                  <span
                                    className="d-block m-t-5"
                                    style={{ color: "white" }}>
                                    0
                                  </span>
                                </div>
                              </div>
                            ))}
                            <div className="col-md-12 m-t-10 text-right textss">
                              <span className="m-r-10">
                                <b>Min: </b>
                                <span>{reqRes?.t2[21]?.min}</span>
                              </span>{" "}
                              <span>
                                <b>Max: </b>
                                <span>{reqRes?.t2[21]?.max}</span>
                              </span>
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <br></br>

                    <div className="row row5">
                      <div className="col-md-6">
                        <div className="d-t-box m-b-10">
                          <div className="row">
                            <div className="col-md-12">
                              <h4 className="m-b-0 text-center text-uppercase textss">
                                {reqRes?.t2[8]?.nat.split(" ")[0]}{" "}
                                {reqRes?.t2[8]?.rate}
                              </h4>
                            </div>{" "}
                            <div
                              data-v-2cf8dad2=""
                              className="card-dt col-md-12 text-center m-t-10">
                              {reqRes?.t2?.slice(8, 21)?.map((item, index) => (
                                <div
                                  className="m-r-5 card-image d-inline-block"
                                  key={index}>
                                  <div className="suspended position-relative">
                                    {item.gstatus == 0 && (
                                      <div className="suspended__2">
                                        <i className="fa fa-lock"></i>
                                      </div>
                                    )}
                                    <img
                                      data-v-2cf8dad2=""
                                      src={imageImports[index]}
                                      alt={item.nat}
                                      onClick={() => {
                                        // setShowModal(true);
                                        // setBetNum(index + 1);
                                        handleModal(item.rate,item.nat,index+9);

                                      }}
                                    />
                                  </div>{" "}
                                  <div className="ubook text-center m-t-5 textss">
                                    <b style={{ color: "white" }}>0</b>
                                  </div>
                                </div>
                              ))}
                            </div>{" "}
                            <div className="col-md-12 m-t-10 text-right textss">
                              <span className="m-r-10">
                                <b data-v-2cf8dad2="">Min: </b>
                                <span data-v-2cf8dad2="">
                                  {reqRes?.t2[8]?.min}
                                </span>
                              </span>{" "}
                              <span data-v-2cf8dad2="">
                                <b data-v-2cf8dad2="">Max: </b>
                                <span data-v-2cf8dad2="">
                                  {reqRes?.t2[8]?.max}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>{" "}
                      <div className="col-md-6">
                        <div className="d-t-box m-b-10">
                          <div className="row">
                            <div className="col-md-12">
                              <h4 className="m-b-0 text-center text-uppercase textss">
                                {reqRes?.t2[21]?.nat.split(" ")[0]}{" "}
                                {reqRes?.t2[21]?.rate}
                              </h4>
                            </div>{" "}
                            <div
                              data-v-2cf8dad2=""
                              className="card-dt col-md-12 text-center m-t-10">
                              {reqRes?.t2?.slice(25, 38)?.map((item, index) => (
                                <div
                                  className="m-r-5 card-image d-inline-block"
                                  key={index}>
                                  <div className="suspended position-relative">
                                    {item.gstatus == 0 && (
                                      <div className="suspended__2">
                                        <i className="fa fa-lock"></i>
                                      </div>
                                    )}
                                    <img
                                      data-v-2cf8dad2=""
                                      src={imageImports[index]}
                                      alt={item.nat}
                                      onClick={() => {
                                        // setShowModal(true);
                                        // setBetNum(index + 1);
                                        handleModal(item.rate,item.nat,index+26);

                                      }}
                                    />
                                  </div>{" "}
                                  <div className="ubook text-center m-t-5 textss">
                                    <b style={{ color: "white" }}>0</b>
                                  </div>
                                </div>
                              ))}
                            </div>{" "}
                            <div className="col-md-12 m-t-10 text-right textss">
                              <span className="m-r-10">
                                <b data-v-2cf8dad2="">Min: </b>
                                <span data-v-2cf8dad2="">
                                  {reqRes?.t2[25]?.min}
                                </span>
                              </span>{" "}
                              <span data-v-2cf8dad2="">
                                <b data-v-2cf8dad2="">Max: </b>
                                <span data-v-2cf8dad2="">
                                  {reqRes?.t2[25]?.max}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="My-Bets-table">
                      <div className="color-game-h mt-2">
                        <div className="px-2 pt-1">
                          <p>Open Bets</p>
                        </div>
                      </div>
                      {HistoryTable}
                      {/* <div className="card-table-inner-row row"></div> */}
                    </div>
                    <div className="Results-table">
                      <div className="color-game-h mt-2">
                        <div className="px-2 pt-1">
                          <p>Last 10 Results</p>
                        </div>
                      </div>
                      <div className="card rst-card py-1 d-flex">
                        <div className="rst-row d-flex">
                          {results?.map((result, index) => {
                            const item = reqRes.t2.find(
                              (item) => item.sid === result?.result
                            );

                            return (
                              <div
                                onClick={() => {
                                  setMidRound(result.mid);
                                  CasinoGameResult_API(result.mid);
                                  setShowresultModal(true);
                                }}
                                className="rst-btn ms-1"
                                key={index}>
                                {item && <>{item.nat[0]}</>}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <RightSidebar />
        {showModal && (
          <Modal
            id={id}
            name={name}
            betDetails={betDetails}
            setbetDetails={setbetDetails}
            setShowModal={setShowModal}
          />
        )}
        {showresultModal && <ResultsModal />}
        {rulesModal && <Rules_Modal closeFunction={setRulesModal} />}
      </div>
    </div>
  );
}

export default DragonTiger2;
