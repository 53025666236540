import { useDispatch } from "react-redux";
import { casinoBet } from "../../Redux/Slices/casinobetSlice";
import { fetchuserBalance } from "../../Redux/Slices/userBalanceSlice";
import { toast } from "react-toastify";
import { fetchCasinobetHistory } from "../../Redux/Slices/casinobetHistory";

const Modal = ({ betDetails, setbetDetails, setShowModal, name, id }) => {
  const dispatch = useDispatch();
  const token = sessionStorage.getItem("token");
  const uid = sessionStorage.getItem("userData");
  // console.log("betDetails==>", betDetails, name, id );
  const handleStake = (value) => {
    setbetDetails({
      ...betDetails,
      userid: uid,
      amount: value,
      winAmount: 0,
    });
  };
  const handlePlacebet = async () => {
    try {
      // if(betDetails.period<=0){
      //   toast.error('Bet Failed !')
      //   return;
      // }
      delete betDetails.nat;
    
      let responce = await dispatch(casinoBet({ betDetails, token })).unwrap();
      // console.log("placebet", responce);
      if (responce.data.result == "Place Bet Successfull") {
        toast.success(responce.data.result);

        dispatch(fetchuserBalance({ id: uid, token }));
        dispatch(fetchCasinobetHistory({ uid, token,id }));

      } else {
        toast.error(responce.data.result);
      }
    } catch (e) {
      console.log("Error", e);
    } finally {
      setShowModal(false);
      setbetDetails({});
    }
  };
  return (
    <>
      <div
        onClick={() => {
          setShowModal(false);
        }}
        className="data-modal-wrapper"
      ></div>
      <div className="data-modal-container">
        <div className="head-border d-flex align-items-center">
          <p className="my-auto mx-2">{name}</p>
        </div>
        <div className="container-body">
          <p className="my-1 mx-2">{betDetails.nat}</p>
          <div className="row">
            <div className="col-4">
              <p className="mx-2 ">Odds</p>
            </div>
            <div className="col-8">
              <button className="modal-w-btn">{betDetails.multipler}</button>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <p className="mx-2 ">Stake</p>
            </div>
            <div className="col-8">
              <button className="modal-w-btn">{betDetails.amount}</button>
            </div>
          </div>
          {/* <div className="row">
            <div className="col-4">
              <p className="mx-2 ">Profit</p>
            </div>
            <div className="col-8">
              <button className="modal-g-btn">19.5</button>
            </div>
          </div> */}
          <div className="orange-buttons-row d-flex flex-wrap">
            <button className="orb mx-1 my-1" onClick={() => handleStake(5)}>
              5
            </button>
            <button className="orb mx-1 my-1" onClick={() => handleStake(10)}>
              10
            </button>
            <button className="orb mx-1 my-1  " onClick={() => handleStake(20)}>
              20
            </button>
            <button className="orb mx-1 my-1" onClick={() => handleStake(50)}>
              50
            </button>
            <button className="orb mx-1 my-1" onClick={() => handleStake(100)}>
              100
            </button>
            <button className="orb mx-1 my-1" onClick={() => handleStake(200)}>
              200
            </button>
            <button className="orb mx-1 my-1" onClick={() => handleStake(500)}>
              500
            </button>
            <button className="orb mx-1 my-1" onClick={() => handleStake(1000)}>
              1000
            </button>
          </div>
        </div>
        <div className="modal-footer row mt-1">
          <div className="col-5"></div>
          <div className="col-7 py-2">
            <button
              onClick={() => {
                setShowModal(false);
              }}
              className="mcb "
            >
              Cancel
            </button>
            <button className="mbb mx-1" onClick={() => handlePlacebet()}>
              Place Bet
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
